
import { DrawingEventType, EntityId, IChartingLibraryWidget } from "@/plugins/tv_charting_library/charting_library";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import { IndicatorConfig, Position, SupportResistancePrice } from "api/models";
import dayjs from "dayjs";
import TVChartContainerBase from "@/components/TVChartContainerBase.vue";
import { MyBaseDatafeed } from "@/service/tv-charting-library/my-base-datafeed";
import { ChartApi } from "@/service/tv-charting-library/chart-api/chart-api";
import { PositionToRender } from "@/service/tv-charting-library/chart-api/positions";

@Component({
  components: { TVChartContainerBase },
})
export default class TVChartContainer extends Vue {
  @Prop()
  marketId?: number;

  @Prop()
  candleSize?: string;

  @Prop()
  datafeed?: MyBaseDatafeed;

  @Prop()
  entryIndicatorConfigs?: Array<IndicatorConfig>;

  @Prop()
  exitIndicatorConfigs?: Array<IndicatorConfig>;

  @Prop()
  supportResistancePrices?: Array<SupportResistancePrice>;

  @Prop()
  positionHistory?: Array<Position>;
  processedPositionIds: Set<number> = new Set();

  visiblePosition?: Position;

  tvWidget?: IChartingLibraryWidget;
  chartApi?: ChartApi;

  @Watch("positionHistory")
  onPositionHistoryChanged() {
    this.createPositionsToRender();
  }

  @Watch("entryIndicatorConfigs")
  onEntryIndicatorConfigsChanged() {
    this.chartApi?.entryIndicatorConfigApi.clear();
    this.chartApi?.entryIndicatorConfigApi.addMany(this.entryIndicatorConfigs || []);
  }

  @Watch("exitIndicatorConfigs")
  onExitIndicatorConfigsChanged() {
    this.chartApi?.exitIndicatorConfigApi.clear();
    this.chartApi?.exitIndicatorConfigApi.addMany(this.exitIndicatorConfigs || []);
  }

  destroyed() {
    if (this.chartApi) {
      this.chartApi.destroy();
    }
  }

  setActivePosition(position?: Position) {
    if (!this.chartApi) {
      console.error("chartApi is not created yet");
      return;
    }

    this.chartApi.positions.setActivePosition(position);
  }

  onWidgetCreated(tvWidget: IChartingLibraryWidget) {
    this.tvWidget = tvWidget;

    this.tvWidget.onChartReady(() => {
      const tvChart = tvWidget.activeChart();
      this.chartApi = new ChartApi(tvWidget, tvChart);
      this.datafeed?.setChartApi(this.chartApi);

      this.chartApi.entryIndicatorConfigApi.addMany(this.entryIndicatorConfigs || []);
      this.chartApi.exitIndicatorConfigApi.addMany(this.exitIndicatorConfigs || []);

      this.chartApi.buttonsApi.init();

      if (this.tvWidget) {
        this.tvWidget.subscribe("drawing_event", (sourceId: EntityId, drawingEventType: DrawingEventType) => {
          if (drawingEventType === "click") {
            const position = this.chartApi?.positions.getPositionByEntityId2(sourceId);
            if (position) {
              this.$emit("click-position", position);
            }
          }
        });
      }

      // For now we don't use supportResistance prices
      // for (const supportResistancePrice of this.supportResistancePrices || []) {
      //   const price = supportResistancePrice.price || 0;
      //   tvChart.createShape({ price: price }, { shape: "horizontal_line" });
      // }

      this.chartApi.positions.activate();

      this.createPositionsToRender();
    });
  }

  clearPositions() {
    if (!this.chartApi) {
      return;
    }

    this.processedPositionIds = new Set();
    this.chartApi.positions.clearPositions();
  }

  createPositionsToRender() {
    if (!this.chartApi) {
      return;
    }

    for (const position of this.positionHistory || []) {
      if (!position.id || this.processedPositionIds.has(position.id)) {
        continue;
      }

      if (position.targetPrice === undefined || position.entryPrice === undefined || position.stopLoss === undefined) {
        console.error("Wrong position price data!");
        continue;
      }

      const from = dayjs(position.entryDate).unix();
      const to = position.closeDate ? dayjs(position.closeDate).unix() : null;
      const positionToRender: PositionToRender = {
        from,
        to,
        position,
        entityId: null,
        entityId2: null,
        entityId3: null,
        interval: null,
      };

      this.chartApi.positions.addPositionToRender(positionToRender);

      if (positionToRender.to !== null) {
        this.processedPositionIds.add(position.id);
      }
    }
  }
}
