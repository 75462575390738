import { IChartWidgetApi } from "@/plugins/tv_charting_library/charting_library";
import { Bar } from "@/plugins/tv_charting_library/datafeed-api";
import { ChartApi } from "@/service/tv-charting-library/chart-api/chart-api";

export class Cache {
  constructor(protected tvChart: IChartWidgetApi, protected chartApi: ChartApi) {}

  protected _getCachedDatafeed() {
    return this.tvChart._chartWidget.model().m_model._chartApi._chartApi._studyEngine._cachedDatafeed;
  }

  getMyBaseDatafeed(ticker?: string, interval?: string) {
    //return this._getCachedDatafeed()._datafeed;
    const datafeedThread = this.getDatafeedThread(ticker, interval);
    return datafeedThread._datafeed;
  }

  getDatafeedThread(ticker?: string, interval?: string) {
    if (!ticker) {
      ticker = this.chartApi.getTicker();
    }
    if (!interval) {
      interval = this.tvChart.resolution();
    }

    const cachedDatafeed = this._getCachedDatafeed();
    const threadName = ticker + "_#_" + interval;
    return cachedDatafeed._threads[threadName];
  }

  purgeCache(ticker?: string, interval?: string) {
    const datafeedThread = this.getDatafeedThread(ticker, interval);
    datafeedThread._purgeCache();
  }

  getCachedBars(ticker?: string, interval?: string): Bar[] | null {
    const datafeedThread = this.getDatafeedThread(ticker, interval);
    if (!datafeedThread) {
      return null;
    }

    return datafeedThread._cache.bars;
  }

  getFirstCachedBar(ticker?: string, interval?: string): Bar | null {
    const datafeedThread = this.getDatafeedThread(ticker, interval);
    if (!datafeedThread) {
      return null;
    }

    const bars = datafeedThread._cache.bars;
    return bars.length ? bars[0] : null;
  }

  getLastCachedBar(ticker?: string, interval?: string): Bar | null {
    const datafeedThread = this.getDatafeedThread(ticker, interval);
    if (!datafeedThread) {
      return null;
    }

    const bars = datafeedThread._cache.bars;
    return bars.length ? bars[bars.length - 1] : null;
  }
}
