import Utils from "./utils";

export default (PineJS) => {
  return {
    name: "Volume Change Rate",
    metainfo: {
      _metainfoVersion: 52,
      shortDescription: "Vol Change Rate",
      name: "Volume Change Rate",
      description: "Volume Change Rate",
      id: "Volume Change Rate@tv-basicstudies-1",
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      is_price_study: false,
      defaults: {
        styles: {
          plot_0: {
            linestyle: 0,
            linewidth: 1,
            plottype: 5,
            trackPrice: !1,
            transparency: 0,
            visible: !0,
            color: "#2196F3",
          },
        },
        palettes: {
          paletteId1: {
            colors: {
              0: {
                color: "#2196F3",
                width: 1,
                style: 0,
              },
              1: {
                color: "#eee",
                width: 1,
                style: 0,
              },
            },
          },
        },
        inputs: { in_0: 50, in_1: 5 },
      },
      plots: [
        { id: "plot_0", type: "line" },
        { id: "plot_1", type: "colorer", target: "plot_0", palette: "paletteId1" },
      ],
      palettes: {
        paletteId1: {
          colors: {
            0: {
              name: "First color",
            },
            1: {
              name: "Second color",
            },
          },
        },
      },
      styles: {
        plot_0: { title: "Oscillator", histogramBase: 0, joinPoints: !1 },
      },
      inputs: [
        { id: "in_0", name: "History Length", defval: 50, type: "integer", min: 1, max: 2e3 },
        { id: "in_1", name: "Current Length", defval: 5, type: "integer", min: 1, max: 2e3 },
      ],
      scriptIdPart: "",
      format: { type: "price", precision: 2 },
    },
    constructor: function () {
      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const volume = PineJS.Std.volume(this._context);

        const series1 = this._context.new_var(volume);
        const series2 = this._context.new_var(volume);

        const historyLength = this._input(0);
        const currentLength = this._input(1);

        const sum1 = Utils.arrSum(series1.hist);
        const sum2 = Utils.arrSum(series2.hist);

        const sumSeries1 = this._context.new_var(sum1);
        const sumSeries2 = this._context.new_var(sum2);

        series1.get(historyLength - 1); //Note: This is necessary to store {length} items in "series1.hist"
        series2.get(currentLength - 1); //Note: This is necessary to store {length} items in "series2.hist"

        const value = sumSeries2.get(0) / sumSeries1.get(currentLength);
        const sigmoidValue = Utils.modifiedSigmoid(value, 0.5);

        return [sigmoidValue, sigmoidValue >= 0.5 ? 0 : 1];
      };
    },
  };
};
