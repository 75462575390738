import { IChartingLibraryWidget, IChartWidgetApi } from "@/plugins/tv_charting_library/charting_library";
import { IndicatorConfigApi } from "@/service/tv-charting-library/chart-api/indicator-config-api";

export class ButtonsApi {
  constructor(
    protected tvWidget: IChartingLibraryWidget,
    protected tvChart: IChartWidgetApi,
    protected entryIndicatorConfigApi: IndicatorConfigApi,
    protected exitIndicatorConfigApi: IndicatorConfigApi
  ) {}

  init() {
    this.tvWidget.headerReady().then(() => {
      this.initButton3();
      this.initButton4();
      //this.initButton1();
      //this.initButton2();
    });
  }

  // initButton1() {
  //   const button = this.tvWidget.createButton();
  //   button.setAttribute("title", "Add Support / Resistance line");
  //   button.addEventListener("click", () => {
  //     this.tvWidget.chart().createShape({ time: dayjs().unix() }, { shape: "horizontal_line" });
  //   });
  //   button.textContent = "Add Line";
  // }
  //
  // initButton2() {
  //   const button2 = this.tvWidget.createButton();
  //   button2.setAttribute("title", "Save Support / Resistance lines");
  //   button2.addEventListener("click", () => {
  //     const prices: Array<number> = [];
  //     this.tvWidget
  //       .activeChart()
  //       .getAllShapes()
  //       .forEach((shapeObj) => {
  //         const price = this.tvWidget.activeChart().getShapeById(shapeObj.id).getPoints()[0].price;
  //         prices.push(price);
  //       });
  //
  //     console.log(prices);
  //     //this.$emit("save-support-resistance-prices", prices);
  //   });
  //   button2.textContent = "Save Lines";
  // }

  initButton3() {
    const button = this.tvWidget.createButton();
    button.setAttribute("title", "Show / hide entry indicators");
    button.addEventListener("click", () => {
      this.entryIndicatorConfigApi.toggleAll();
    });
    button.textContent = "Entry";
  }

  initButton4() {
    const button = this.tvWidget.createButton();
    button.setAttribute("title", "Show / hide exit indicators");
    button.addEventListener("click", () => {
      this.exitIndicatorConfigApi.toggleAll();
    });
    button.textContent = "Exit";
  }
}
