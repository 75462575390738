
import {
  AvailableSaveloadVersions,
  ChartingLibraryWidgetOptions,
  IBasicDataFeed,
  IChartingLibraryWidget,
  StudyOverrides,
  widget,
} from "@/plugins/tv_charting_library/charting_library";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { exitHandlerIndicator } from "@/service/tv-charting-library/exitHandlerIndicator";
import { entryHandlerIndicator } from "@/service/tv-charting-library/entryHandlerIndicator";
import { testIndicator } from "@/service/tv-charting-library/testIndicator";
import volumeAboveAverage from "@/service/tv-charting-library/indicators/volumeAboveAverage";
import volumeAboveMax from "@/service/tv-charting-library/indicators/volumeAboveMax";
import priceChangeAboveAverage from "@/service/tv-charting-library/indicators/priceChangeAboveAverage";
import maximumPrice from "@/service/tv-charting-library/indicators/maximumPrice";
import volumeChangeRate from "@/service/tv-charting-library/indicators/volumeChangeRate";
import priceChange from "@/service/tv-charting-library/indicators/priceChange";

const ns = "profile";

@Component({
  computed: {
    ...mapGetters(ns, {
      user: "user",
    }),
  },
})
export default class TVChartContainerBase extends Vue {
  @Prop()
  marketId?: string;

  @Prop()
  candleSize?: string;

  @Prop({ default: "tv_chart_container" })
  containerId?: AvailableSaveloadVersions;

  @Prop({ required: true })
  datafeed?: IBasicDataFeed;

  @Prop({ default: "/tv_charting_library/" })
  libraryPath?: string;

  @Prop({ default: "https://saveload.tradingview.com" })
  chartsStorageUrl?: string;

  @Prop({ default: "1.1" })
  chartsStorageApiVersion?: AvailableSaveloadVersions;

  @Prop({ default: "tradingview.com" })
  clientId?: string;

  @Prop({ default: "public_user_id" })
  userId?: string;

  @Prop({ default: false })
  fullscreen?: boolean;

  @Prop({ default: true })
  autosize?: boolean;

  @Prop()
  studiesOverrides?: StudyOverrides;

  tvWidget?: IChartingLibraryWidget;

  mounted() {
    this.createWidget();
  }

  @Watch("marketId")
  @Watch("candleSize")
  onMarketIdOrCandleSizeChanged() {
    const ticker = this.marketId.toString();
    this.tvWidget?.setSymbol(ticker, this.getInterval());
  }

  createWidget() {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      //debug: true,
      symbol: this.marketId.toString(), //NOTE This needs to be string as tvChart waits for it
      datafeed: this.datafeed,
      interval: this.getInterval(),
      container: document.getElementById(this.containerId),
      library_path: this.libraryPath,
      timezone: "Europe/Berlin",

      locale: "en",
      disabled_features: ["use_localstorage_for_settings", "control_bar"],
      enabled_features: ["seconds_resolution", "tick_resolution", "countdown", "low_density_bars"],
      // "low_density_bars" is necessary to align the most right bar to the right side without any gap when resetting the chart (when showing a position)
      charts_storage_url: this.chartsStorageUrl,
      charts_storage_api_version: this.chartsStorageApiVersion,
      client_id: this.clientId,
      user_id: this.userId,
      fullscreen: this.fullscreen,
      autosize: this.autosize,
      studies_overrides: this.studiesOverrides,
      custom_css_url: "../../css/tv_override.css",
      overrides: {
        "mainSeriesProperties.showCountdown": true,
      },
      time_frames: [],
      custom_formatters: {
        dateFormatter: {
          format: function (date) {
            return (
              date.getUTCFullYear() +
              "-" +
              (date.getUTCMonth() + 1).toString().padStart(2, "0") +
              "-" +
              date.getUTCDate().toString().padStart(2, "0")
            );
          },
        },
      },
      custom_indicators_getter: (PineJS) => {
        return Promise.resolve([
          exitHandlerIndicator(PineJS),
          entryHandlerIndicator(PineJS),
          testIndicator(PineJS),
          volumeAboveAverage(PineJS),
          volumeAboveMax(PineJS),
          priceChangeAboveAverage(PineJS),
          maximumPrice(PineJS),
          volumeChangeRate(PineJS),
          priceChange(PineJS),
        ]);
      },
    };

    this.tvWidget = new widget(widgetOptions);
    this.$emit("widget-created", this.tvWidget);
  }

  getInterval() {
    if (this.candleSize === "1s") {
      return "1S";
    }
    if (this.candleSize === "5s") {
      return "5S";
    }
    if (this.candleSize === "15s") {
      return "15S";
    }
    if (this.candleSize === "30s") {
      return "30S";
    }
    if (this.candleSize === "1m") {
      return "1";
    }
    if (this.candleSize === "3m") {
      return "3";
    }
    if (this.candleSize === "5m") {
      return "5";
    }
    if (this.candleSize === "15m") {
      return "15";
    }
    if (this.candleSize === "30m") {
      return "30";
    }
    if (this.candleSize === "1h") {
      return "60";
    }
    if (this.candleSize === "2h") {
      return "120";
    }
    if (this.candleSize === "6h") {
      return "360";
    }
    if (this.candleSize === "12h") {
      return "720";
    }
    if (this.candleSize === "1d") {
      return "1D";
    }
    if (this.candleSize === "3d") {
      return "3D";
    }

    return "60";
  }

  destroyed() {
    if (this.tvWidget) {
      this.tvWidget.remove();
      this.tvWidget = undefined;
    }
  }
}
