
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { mdiChevronDown, mdiChevronRight, mdiChevronUp } from "@mdi/js";

@Component
export default class ProfitPercent extends Vue {
  @Prop({ required: true })
  netProfitPercent?: number;

  @Prop()
  netProfit?: number;

  @Prop()
  currencyName?: string;

  icons = {
    mdiChevronUp: mdiChevronUp,
    mdiChevronDown: mdiChevronDown,
    mdiChevronRight: mdiChevronRight,
  };

  getIconColor() {
    const netProfitPercent = this.netProfitPercent || 0;
    return netProfitPercent !== 0 ? (netProfitPercent > 0 ? "green" : "red") : "";
  }

  getNetProfitPercent() {
    return Math.round(Math.abs(this.netProfitPercent || 0) * 100) / 100 + "%";
  }
}
