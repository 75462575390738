export default (PineJS) => {
  return {
    name: "Maximum price",
    metainfo: {
      _metainfoVersion: 52,
      shortDescription: "Max Price",
      name: "Maximum price",
      description: "Maximum price",
      id: "Maximum price@tv-basicstudies-1",
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      is_price_study: true,
      defaults: {
        styles: {
          plot_0: {
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: !1,
            transparency: 0,
            visible: !0,
            color: "#2196F3",
          },
        },
        inputs: { in_0: 50 },
      },
      plots: [{ id: "plot_0", type: "line" }],
      styles: {
        plot_0: { title: "Max", histogramBase: 0, joinPoints: !1 },
      },
      inputs: [{ id: "in_0", name: "length", defval: 50, type: "integer", min: 1, max: 2e3 }],
      scriptIdPart: "",
      format: { type: "inherit" },
    },
    constructor: function () {
      this.main = function (e, t) {
        this._context = e;
        this._input = t;
        const i = this._input(0),
          a = PineJS.Std.high(this._context),
          l = this._context.new_var(a),
          c = PineJS.Std.highest(l, i, this._context);
        return [c];
      };
    },
  };
};
