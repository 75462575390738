
import Component from "vue-class-component";
import Vue from "vue";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import { mdiArrowLeft, mdiChartLineVariant, mdiListStatus } from "@mdi/js";
import AvatarIdenticon from "@/components/General/AvatarIdenticon.vue";
import { Prop } from "vue-property-decorator";
import { StrategyRun } from "api/models";

@Component({
  components: {
    Splitpanes,
    Pane,
    AvatarIdenticon,
  },
})
export default class BackTestTemplate extends Vue {
  @Prop({ required: true })
  strategyRun?: StrategyRun;

  icons = {
    mdiChartLineVariant: mdiChartLineVariant,
    mdiListStatus: mdiListStatus,
    mdiArrowLeft: mdiArrowLeft,
  };

  tab = "overview";
}
