import { Bar } from "@/plugins/tv_charting_library/datafeed-api";
import OhlcvCandle from "@/model/OhlcvCandle";
import { SubscribeBarsCallback } from "@/plugins/tv_charting_library/datafeed-api";
import { ChartApi } from "@/service/tv-charting-library/chart-api/chart-api";
import { throttle } from "lodash-es";
import { LiveDatafeed } from "@/service/tv-charting-library/live-datafeed";
import { PeriodParamsWithOptionalCountback } from "@/plugins/tv_datafeeds/history-provider";
import { ResolutionString } from "@/plugins/tv_charting_library/charting_library";
import { SymbolInfo } from "@/service/tv-charting-library/chart-api/types";

export class LiveDataSocketHandler {
  private chartApi?: ChartApi;

  private lastCandle?: OhlcvCandle;
  private lastClosedUnusedCandle?: OhlcvCandle;
  private requesting = false;

  constructor(
    public readonly symbolInfo: SymbolInfo,
    public readonly interval: string,
    private liveDatafeed: LiveDatafeed,
    private enabled: boolean,
    private newDataCallback: SubscribeBarsCallback
  ) {}

  setChartApi(chartApi: ChartApi) {
    this.chartApi = chartApi;
  }

  getLastCandle() {
    return this.lastCandle;
  }

  disable() {
    this.enabled = false;
  }

  enable() {
    this.enabled = true;
  }

  //NOTE: Needs to be in this variable/function form,
  //      so that local variables are visible when using as a callback for socket.on()
  socketListener = (arrayBuffer: ArrayBuffer) => {
    if (!this.enabled) {
      return;
    }

    const candle = OhlcvCandle.createFromArrayBuffer(arrayBuffer);
    this.handleCandle(candle);
  };

  handleCandle(candle: OhlcvCandle, fetched = false) {
    const cache = this.chartApi?.cache;
    const datafeedThread = cache?.getDatafeedThread(this.symbolInfo.ticker, this.interval);
    const lastBar = cache?.getLastCachedBar(this.symbolInfo.ticker, this.interval);
    const intervalInMs = this.chartApi?.getIntervalInMs(this.interval);
    if (!datafeedThread || !intervalInMs) {
      return;
    }

    if (!fetched && candle.timestamp % 10000 === 1000) {
      //console.log("SKIP", candle.timestamp);
      //return;
    }

    // If there is no lastBar or requesting
    if (!lastBar || this.requesting) {
      this.saveUnusedCandleIfClosed(candle);
      return;
    }

    // If candle has the same timestamp
    if (lastBar.time === candle.timestamp) {
      this.addCandle(candle);
      return;
    }

    // If candle is next to the last UNUSED closed candle
    if (
      this.lastClosedUnusedCandle &&
      this.lastClosedUnusedCandle.timestamp === lastBar.time &&
      this.lastClosedUnusedCandle.timestamp + intervalInMs === candle.timestamp
    ) {
      this.addCandle(this.lastClosedUnusedCandle); // Unused candles needs to be added first
      this.addCandle(candle);
      return;
    }

    // If candle is next to the last closed candle
    if (
      this.lastCandle &&
      this.lastCandle.isClosed &&
      this.lastCandle.timestamp === lastBar.time &&
      this.lastCandle.timestamp + intervalInMs === candle.timestamp
    ) {
      this.addCandle(candle);
      return;
    }

    this.throttledSendRequestForCandles(lastBar.time, candle.timestamp, intervalInMs);
    this.saveUnusedCandleIfClosed(candle);
  }

  private saveUnusedCandleIfClosed(candle: OhlcvCandle) {
    if (candle.isClosed) {
      //console.log("Set lastClosed", candle.timestamp);
      this.lastClosedUnusedCandle = candle;
    }
  }

  private addCandle(candle: OhlcvCandle) {
    const bar: Bar = {
      time: candle.timestamp,
      open: candle.open,
      high: candle.high,
      low: candle.low,
      close: candle.close,
      volume: candle.volume,
    };

    this.lastCandle = candle;
    this.newDataCallback(bar);
  }

  private throttledSendRequestForCandles = throttle(this.sendRequestForCandles, 300, {
    trailing: true,
    leading: true,
  });

  private sendRequestForCandles(from: number, to: number, intervalInMs: number) {
    if (this.lastCandle) {
      console.info(this.symbolInfo.ticker, this.interval, "handleCandle: Need to request data", from, to);
    } else {
      console.info(this.symbolInfo.ticker, this.interval, "handleAsFirstCandle: Need to request data", from, to);
    }

    const chartApi = this.chartApi;
    if (!chartApi) {
      return;
    }

    //NOTE: These parameters will be overwritten with endDate.
    //      One case is when sec candles are not working on live-data,
    //      and we switch from sec timeframe to any non-sec timeframe
    const periodParams: PeriodParamsWithOptionalCountback = {
      from: from / 1000,
      to: (to + intervalInMs) / 1000,
      firstDataRequest: false,
    };

    this.requesting = true;
    this.liveDatafeed.getBars(
      this.symbolInfo,
      this.interval as ResolutionString,
      periodParams,
      (bars) => {
        this.requesting = false;
        const candles = this.parseBars(bars);
        for (const candle of candles) {
          this.handleCandle(candle, true);
        }
      },
      (error) => {
        this.requesting = false;
        console.error("Error", error);
      }
    );
  }

  parseBars(bars: Bar[]) {
    const candles: Array<OhlcvCandle> = [];
    for (const bar of bars) {
      candles.push(OhlcvCandle.createFromBar(bar));
    }
    if (candles.length) {
      candles[candles.length - 1].isOpen = true;
    }
    return candles;
  }
}
