import {
  EntityId,
  IChartingLibraryWidget,
  IChartWidgetApi,
  ResolutionString,
  VisibleTimeRange,
} from "@/plugins/tv_charting_library/charting_library";
import { Events, IntervalChangedParams } from "@/service/tv-charting-library/chart-api/events";
import { Position } from "api/models";
import { debounce } from "rxjs/operators";
import { throttle as _throttle } from "lodash-es";
import { interval, Subscription } from "rxjs";
import { Cache } from "@/service/tv-charting-library/chart-api/cache";
import dayjs from "dayjs";
import { LiveDatafeed } from "@/service/tv-charting-library/live-datafeed";
import { ChartApi } from "@/service/tv-charting-library/chart-api/chart-api";
import { IntervalChangeHandler } from "@/service/tv-charting-library/chart-api/interval-change-handler";
import { ChartUtils } from "@/service/tv-charting-library/chart-api/chart-utils";

export interface PositionToRender {
  from: number;
  to: number | null;
  position: Position;
  entityId: EntityId | null;
  entityId2: EntityId | null;
  entityId3: EntityId | null;
  interval: string | null;
}

export class Positions {
  protected activated = false;
  protected positionsToRender: Map<number, PositionToRender> = new Map();
  protected activePosition?: Position;
  protected subscriptions: Subscription[] = [];
  protected intervalChangeHandler: IntervalChangeHandler;
  protected beforeZoomVisibleRange?: VisibleTimeRange;
  protected entityId2Map: Map<EntityId, PositionToRender> = new Map();

  constructor(
    protected tvWidget: IChartingLibraryWidget,
    protected tvChart: IChartWidgetApi,
    protected chartApi: ChartApi,
    protected events: Events,
    protected cache: Cache
  ) {
    this.intervalChangeHandler = new IntervalChangeHandler(this.tvChart, this, this.cache);
  }

  activate() {
    this.activated = true;

    // dataReady
    this.tvChart.dataReady(() => {
      const visibleRange = this.tvChart.getVisibleRange();
      this._writePositions(visibleRange);
    });

    // onVisibleRangeChanged
    this.subscriptions.push(
      this.events
        .onVisibleRangeChanged()
        .pipe(debounce(() => interval(200)))
        .subscribe((visibleRange: VisibleTimeRange) => {
          const scrollPosition = this.tvChart.scrollPosition();
          if (scrollPosition > 0) {
            this._resetEndDateIfNeeded(visibleRange);
            this._loadDataToRight(visibleRange);
          }
          this._writePositions(visibleRange);
        })
    );

    // onIntervalChanged
    this.intervalChangeHandler.initLastInterval();
    this.subscriptions.push(
      this.events.onIntervalChanged().subscribe((next: IntervalChangedParams) => {
        this.intervalChangeHandler.handle(next.interval, next.timeFrameParameters, this.beforeZoomVisibleRange);
      })
    );

    this.subscriptions.push(
      this.events.onZoom().subscribe((next) => {
        this.onZoom(next.zoomValue, next.event);
      })
    );
  }

  destroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  checkActivated() {
    if (!this.activated) {
      console.error("Positions feature is not activated!");
    }
    return this.activated;
  }

  addPositionToRender(positionToRender: PositionToRender) {
    const position = positionToRender.position;
    if (!this.checkActivated() || !position.id) return;

    const _positionToRender = this.positionsToRender.get(position.id);
    if (_positionToRender !== undefined) {
      _positionToRender.from = positionToRender.from;
      _positionToRender.to = positionToRender.to;
      _positionToRender.position = positionToRender.position;
    } else {
      this.positionsToRender.set(position.id, positionToRender);
    }
  }

  getActivePosition() {
    return this.activePosition;
  }

  clearPositions() {
    for (const positionToRender of this.positionsToRender.values()) {
      if (positionToRender.entityId !== null) {
        this.tvChart.removeEntity(positionToRender.entityId);
        positionToRender.entityId = null;
      }
      if (positionToRender.entityId2 !== null) {
        this.tvChart.removeEntity(positionToRender.entityId2);
        positionToRender.entityId2 = null;
      }
      if (positionToRender.entityId3 !== null) {
        this.tvChart.removeEntity(positionToRender.entityId3);
        positionToRender.entityId3 = null;
      }
    }

    this.positionsToRender = new Map();
    this.entityId2Map.clear();
    this.activePosition = undefined;
  }

  protected _writePositions(visibleRange: VisibleTimeRange) {
    const interval = this.chartApi.getInterval();

    this.positionsToRender.forEach((positionToRender) => {
      //Remove position if it's not the active one
      if (this.activePosition && positionToRender.position.id !== this.activePosition.id) {
        this.removePosition(positionToRender);
        return;
      }

      //Remove position if it's not on the current market
      const position = positionToRender.position;
      if (position.market?.id?.toString() !== this.chartApi.getTicker()) {
        this.removePosition(positionToRender);
        return;
      }

      const from = positionToRender.from;
      let to = positionToRender.to;
      if (to === null) {
        const intervalInSec = this.chartApi.getIntervalInSec();
        to = this.chartApi.nowResampled() + intervalInSec * 2;
      }

      //Remove position if it's out of visible range
      if (to < visibleRange.from || from > visibleRange.to) {
        //console.log("Position is out of visible range!");
        this.removePosition(positionToRender);
        return;
      }

      //Remove position if the timeframe has been changed
      if (positionToRender.interval !== null && positionToRender.interval !== interval) {
        this.removePosition(positionToRender);
      }

      //if (this.activePosition && positionToRender.position === this.activePosition) {
      //NOTE We remove the position anyway, so that we rerender on tvChart
      this.removePosition(positionToRender);
      //}

      //Do not render incomplete position
      if (!position.entryPrice || !position.targetPrice || !position.stopLoss) {
        //console.log('Position has no sufficient data!');
        return;
      }

      //Do not create new shapes on the chart if it's already there
      if (
        positionToRender.entityId !== null ||
        positionToRender.entityId2 !== null ||
        positionToRender.entityId3 !== null
      ) {
        return;
      }

      const priceScale = this.tvChart._chartWidget.model().m_model.m_mainSeries.m_priceScale._formatter._priceScale;

      let profitLevel;
      let stopLevel;
      if (position.isLong) {
        profitLevel = (position.targetPrice - position.entryPrice) * priceScale;
        stopLevel = (position.entryPrice - position.stopLoss) * priceScale;
      } else {
        profitLevel = (position.entryPrice - position.targetPrice) * priceScale;
        stopLevel = (position.stopLoss - position.entryPrice) * priceScale;
      }

      let renderOnlyArrow = from === to || !this.activePosition;
      if (positionToRender.to === null) {
        renderOnlyArrow = false;
      }

      // const chartCache = this.chartApi.cache;
      //
      // if (chartCache.getFirstCachedBar()?.time > from * 1000) {
      //   //We do not render the position if the data for the position's "from" time is not loaded yet
      //   console.log("Position not rendered, data is loaded yet", chartCache.getFirstCachedBar()?.time / 1000, from);
      //   return;
      // }

      //console.log("Render position", from, to, new Date(from * 1000), new Date(to * 1000));
      positionToRender.interval = interval;
      if (!renderOnlyArrow) {
        positionToRender.entityId = this.tvChart.createMultipointShape(
          [{ time: from, price: position.entryPrice }, { time: to - 1 }],
          {
            shape: position.isLong ? "long_position" : "short_position",
            lock: true,
            disableSelection: true,
            showInObjectsTree: false,
            overrides: {
              profitLevel: profitLevel,
              stopLevel: stopLevel,
              showPriceLabels: false, //Do not show price labels on right price scale
              accountSize: 1000,
              profitBackground: "#00A000",
              profitBackgroundTransparency: position.profit ? (position.profit >= 0 ? 90 : 95) : 95,
              stopBackground: "#FF0000",
              stopBackgroundTransparency: position.profit ? (position.profit < 0 ? 90 : 95) : 95,
              lineWidth: 3,
            },
          }
        );
        if (position.closePrice) {
          positionToRender.entityId3 = this.tvChart.createMultipointShape(
            [
              { time: from, price: position.entryPrice },
              { time: to - 1, price: position.closePrice },
            ],
            {
              shape: "arrow",
              zOrder: "top",
              lock: true,
              disableSelection: true,
              showInObjectsTree: false,
              overrides: {
                showPriceLabels: false, //Do not show price labels on right price scale
              },
            }
          );
        }
      }
      if (from >= visibleRange.from && from <= visibleRange.to) {
        positionToRender.entityId2 = this.tvChart.createShape(
          {
            time: from,
            channel: "high",
          },
          {
            shape: "arrow_down",
            zOrder: "top",
            lock: true,
            disableSelection: true,
            showInObjectsTree: false,
            overrides: {
              arrowColor: "blue",
            },
          }
        );
      }
      if (positionToRender.entityId2 !== null) {
        this.entityId2Map.set(positionToRender.entityId2, positionToRender);
      }
    });
  }

  removePosition(positionToRender: PositionToRender) {
    positionToRender.interval = null;
    if (positionToRender.entityId !== null) {
      this.tvChart.removeEntity(positionToRender.entityId);
      positionToRender.entityId = null;
    }
    if (positionToRender.entityId2 !== null) {
      this.tvChart.removeEntity(positionToRender.entityId2);
      this.entityId2Map.delete(positionToRender.entityId2);
      positionToRender.entityId2 = null;
    }
    if (positionToRender.entityId3 !== null) {
      this.tvChart.removeEntity(positionToRender.entityId3);
      positionToRender.entityId3 = null;
    }
  }

  getPositionByEntityId2(entityId: EntityId): Position | undefined {
    const positionToRender = this.entityId2Map.get(entityId);
    if (positionToRender !== undefined) {
      return positionToRender.position;
    }
  }

  setActivePosition(position?: Position) {
    if (!this.checkActivated()) return;

    const visibleRange = this.tvChart.getVisibleRange();
    const visibleTimeDuration = visibleRange.to - visibleRange.from;

    const currentTicker = this.chartApi.getTicker();
    const positionTicker = position?.market?.id?.toString();
    if (positionTicker !== undefined && positionTicker !== currentTicker) {
      //NOTE: We need to call getVisibleRange() above to have visibleTimeDuration before we call setSymbol,
      //      because if we call it after setSymbol the visibleTimeDuration will be less than it should be.
      //      Maybe when changing symbol the default visible range is smaller.
      this.tvChart.setSymbol(positionTicker, () => {
        this._setActivePosition(visibleTimeDuration, position);
      });
      return;
    }

    this._setActivePosition(visibleTimeDuration, position);
  }

  protected _setActivePosition(visibleTimeDuration: number, position?: Position) {
    const ticker = this.chartApi.getTicker();
    const interval = this.chartApi.getInterval();
    const datafeed = this.cache.getMyBaseDatafeed();

    this.activePosition = position;
    if (this.activePosition === undefined || !this.activePosition.isClosed) {
      if (datafeed instanceof LiveDatafeed) {
        datafeed.resetData(ticker, interval);
        this.tvChart.getTimeScale().setRightOffset(10);
        this.tvChart.resetData();
      } else {
        const visibleRange = this.tvChart.getVisibleRange();
        this._writePositions(visibleRange);
      }
      return;
    }

    const positionEntryDate = dayjs.utc(this.activePosition.entryDate).unix();
    const positionCloseDate = dayjs.utc(this.activePosition.closeDate).unix();
    const positionDuration = positionCloseDate - positionEntryDate;
    let endDate;
    if (positionDuration > visibleTimeDuration * 0.8) {
      // We keep 10% distance on the left side
      endDate = positionEntryDate + visibleTimeDuration * 0.9;
    } else {
      const positionMiddleDate = positionEntryDate + positionDuration / 2;
      endDate = positionMiddleDate + visibleTimeDuration / 2;
    }
    endDate = this.chartApi.resample(endDate);

    // We query 15% more data to the left side than what is currently visible,
    // to make sure the chart will not load extra data for caching
    let startDate = endDate - visibleTimeDuration * 1.15;
    startDate = this.chartApi.resample(startDate);

    //console.log(endDate - startDate, new Date(startDate * 1000), new Date(endDate * 1000));
    datafeed.resetDataByRange(ticker, interval, endDate, startDate);
    this.tvChart.getTimeScale().setRightOffset(0);
    this.tvChart.resetData();
  }

  protected _resetEndDateIfNeeded(visibleRange: VisibleTimeRange) {
    const datafeed = this.cache.getMyBaseDatafeed();
    const endDate = datafeed.getEndDate();
    if (endDate === undefined) {
      return;
    }

    const nowResampled = this.chartApi.nowResampled();
    const resetNeeded = visibleRange.to >= nowResampled;
    if (resetNeeded) {
      const ticker = this.chartApi.getTicker();
      const interval = this.chartApi.getInterval();
      datafeed.resetData(ticker, interval);
      this.tvChart.resetData();
    }
  }

  protected _loadDataToRight(visibleRange: VisibleTimeRange) {
    const datafeed = this.cache.getMyBaseDatafeed();
    const currentEndDate = datafeed.getEndDate();
    if (currentEndDate === undefined) {
      //NOTE: If endDate is not set, we do not need to load data to right,
      //      data have to be already loaded.
      return;
    }

    const lastBar = this.cache.getLastCachedBar();
    if (!lastBar) {
      return;
    }

    const ticker = this.chartApi.getTicker();
    const interval = this.chartApi.getInterval();
    const intervalInSec = this.chartApi.getIntervalInSec();
    const maxEndDate = datafeed.getMaxEndDate();

    if (maxEndDate !== undefined) {
      if (lastBar.time + intervalInSec * 1000 === maxEndDate * 1000) {
        //We do not reset if the most right bar is already loaded
        return;
      }
    }

    let endDate = visibleRange.to;
    endDate = this.chartApi.resample(endDate);

    // We query 15% more data to the left side than what is currently visible,
    // to make sure the chart will not load extra data for caching
    let startDate = endDate - (visibleRange.to - visibleRange.from) * 1.15;
    startDate = this.chartApi.resample(startDate);

    const maxEndDateWillBeVisible = maxEndDate ? endDate > maxEndDate : false;

    datafeed.resetDataByRange(ticker, interval, endDate, startDate);
    if (!maxEndDateWillBeVisible) {
      this.tvChart.getTimeScale().setRightOffset(0);
    }
    this.tvChart.resetData();
  }

  private onZoom(zoomValue: number, event: WheelEvent) {
    if (!event.altKey) {
      return;
    }

    const interval = this.chartApi.getInterval();
    const ticker = this.chartApi.getTicker();

    const map1 = new Map();
    map1.set("1S", "5S");
    map1.set("5S", "15S");
    map1.set("15S", "30S");
    map1.set("30S", "1");
    map1.set("1", "3");
    map1.set("3", "5");
    map1.set("5", "15");
    map1.set("15", "30");
    map1.set("30", "60");
    map1.set("60", "120");
    map1.set("120", "360");
    map1.set("360", "720");
    map1.set("720", "1D");
    map1.set("1D", "3D");

    const map2 = new Map();
    map2.set("3D", "1D");
    map2.set("1D", "720");
    map2.set("720", "360");
    map2.set("360", "120");
    map2.set("120", "60");
    map2.set("60", "30");
    map2.set("30", "15");
    map2.set("15", "5");
    map2.set("5", "3");
    map2.set("3", "1");
    map2.set("1", "30S");
    map2.set("30S", "15S");
    map2.set("15S", "5S");
    map2.set("5S", "1S");

    let newInterval;

    const zoomOut = zoomValue < 0;
    const zoomIn = zoomValue > 0;
    if (zoomOut) {
      newInterval = map1.get(interval);
    }
    if (zoomIn) {
      newInterval = map2.get(interval);
    }

    if (!newInterval) {
      return;
    }

    const intervalInSec = ChartUtils.convertIntervalToSec(interval);
    const newIntervalInSec = ChartUtils.convertIntervalToSec(newInterval);
    const ratio = intervalInSec / newIntervalInSec;

    const visibleRange = this.tvChart.getVisibleRange();
    const visibleTimeDuration = visibleRange.to - visibleRange.from;
    const newVisibleTimeDuration = visibleTimeDuration * ratio;
    const barNumbers = newVisibleTimeDuration / intervalInSec;

    const maxBarNumbers = 1000;
    const minBarNumbers = 10;
    //console.log("barNumbers zoom", barNumbers);

    if ((zoomIn && barNumbers > maxBarNumbers) || (zoomOut && barNumbers < minBarNumbers)) {
      return;
    }

    this.throttledChangeInterval(ticker, interval, newInterval);
  }

  private throttledChangeInterval = _throttle(this.changeInterval, 300, { leading: true, trailing: false });

  changeInterval(ticker: string, interval: string, newInterval: string) {
    this.beforeZoomVisibleRange = this.tvChart.getVisibleRange();

    //console.log("changing interval from ", interval, " to ", newInterval);
    this.tvWidget.setSymbol(ticker, newInterval as ResolutionString, () => {
      this.beforeZoomVisibleRange = undefined;
    });
  }
}
