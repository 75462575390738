
//import echarts from "echarts/lib/echarts";
//import "echarts/lib/chart/line";
//import "echarts/lib/chart/bar";
//import "echarts/lib/chart/candlestick";
//import "echarts/lib/component/dataZoom";
//import "echarts/lib/component/tooltip";
//import "echarts";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

const index = {
  date: 0,
  open: 1,
  high: 2,
  low: 3,
  close: 4,
  volume: 5,
  markers: 6,
  ma10: 7,
  ma20: 8,
  rsi: 9,
  targetPrice: 10,
  stopLoss: 11,
  profit: 12,
  hold: 13,
  capital: 14,
  ema: 15,
  sar: 16,
  stochFast: 17,
  stochSlow: 18,
};

const upColor = "#00da3c";
const upBorderColor = "#008F28";
const downColor = "#ec0000";
const downBorderColor = "#8A0000";

@Component
export default class BackTestChart extends Vue {
  @Prop()
  chartData: any;

  @Prop()
  title?: string;

  @Watch("chartData")
  onChartDataChange() {
    this.options.title.text = this.title;
    this.options.dataset.source = this.chartData;
  }

  mounted() {
    this.options.dataset.source = this.chartData;

    const data = [];
    const data2 = [];
    for (const [i, item] of this.chartData.entries()) {
      for (const marker of item[index.markers]) {
        if (marker[0] === "buy") {
          const high = item[index.high];
          data.push({
            name: "XX标点",
            coord: [i, high],
            value: marker[1],
            itemStyle: {
              color: "green",
            },
          });
        }

        if (marker[0] === "sell") {
          const high = item[index.high];
          data.push({
            name: "XX标点",
            coord: [i, high],
            value: marker[1],
            itemStyle: {
              color: "red",
            },
          });
        }

        if (marker[0] === "closeBuy") {
          data2.push([
            {
              xAxis: i - 1,
              itemStyle: {
                color: marker[1] === "win" ? "green" : "red",
              },
            },
            {
              xAxis: i,
            },
          ]);
        }

        if (marker[0] === "closeSell") {
          data2.push([
            {
              xAxis: i - 1,
              itemStyle: {
                color: marker[1] === "win" ? "green" : "red",
              },
            },
            {
              xAxis: i,
            },
          ]);
        }
      }
    }
    this.options.series[0].markPoint.data = data;
    this.options.series[0].markArea.data = data2;
  }

  private options = {
    animation: false,
    dataset: {
      source: [],
    },
    title: {
      text: this.title,
      top: 15,
      left: 15,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: false,
        },
      },
    },
    grid: [
      {
        left: "4%",
        right: "4%",
        bottom: 180,
      },
      {
        left: "4%",
        right: "4%",
        height: 80,
        bottom: 50,
      },
    ],
    xAxis: [
      {
        type: "category",
        scale: true,
        boundaryGap: false,
        // inverse: true,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: "dataMin",
        max: "dataMax",
      },
      {
        type: "category",
        gridIndex: 1,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: { show: false },
        splitNumber: 20,
        min: "dataMin",
        max: "dataMax",
      },
    ],
    yAxis: [
      {
        scale: true,
        splitArea: {
          show: true,
        },
      },
      {
        scale: true,
        splitNumber: 2,
        max: function (value) {
          return value.max * 5;
        },
      },
      {
        scale: true,
        gridIndex: 1,
      },
    ],
    dataZoom: [
      {
        type: "inside",
        xAxisIndex: [0, 1],
        start: 0,
        end: 10,
      },
      {
        show: true,
        xAxisIndex: [0, 1],
        type: "slider",
        bottom: 10,
        start: 0,
        end: 10,
        handleIcon:
          "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
        handleSize: "105%",
      },
    ],
    series: [
      {
        type: "candlestick",
        itemStyle: {
          color: upColor,
          color0: downColor,
          borderColor: upBorderColor,
          borderColor0: downBorderColor,
        },
        encode: {
          x: index.date,
          y: [index.open, index.close, index.low, index.high],
        },
        markPoint: {
          label: {
            normal: {
              formatter: function (param) {
                return param != null ? Math.round(param.value) : "";
              },
            },
          },
          data: [],
          tooltip: {
            formatter: function (param) {
              return param.name + "<br>" + (param.data.coord || "");
            },
          },
        },
        markArea: {
          data: [],
        },
      },
      {
        name: "MA10",
        type: "line",
        symbol: "none",
        encode: {
          x: index.date,
          y: index.ma10,
        },
        smooth: true,
      },
      {
        name: "MA20",
        type: "line",
        symbol: "none",
        encode: {
          x: index.date,
          y: index.ma20,
        },
        smooth: true,
      },
      {
        name: "EMA14",
        type: "line",
        symbol: "none",
        encode: {
          x: index.date,
          y: index.ema,
        },
        smooth: true,
      },
      {
        name: "SAR",
        type: "scatter",
        symbolSize: 3,
        encode: {
          x: index.date,
          y: index.sar,
        },
        smooth: true,
      },
      // {
      //   name: "Volumn",
      //   type: "bar",
      //   xAxisIndex: 0,
      //   yAxisIndex: 1,
      //   itemStyle: {
      //     color: "#7fbe9e",
      //     opacity: 0.5
      //   },
      //   large: true,
      //   encode: {
      //     x: index.date,
      //     y: index.volume
      //   }
      // },
      // {
      //   name: "RSI",
      //   type: "line",
      //   symbol: "none",
      //   xAxisIndex: 1,
      //   yAxisIndex: 2,
      //   itemStyle: {
      //     color: "#7fbe9e"
      //   },
      //   large: true,
      //   encode: {
      //     x: index.date,
      //     y: index.rsi
      //   },
      //   markArea: {
      //     data: [
      //       [
      //         {
      //           yAxis: 30
      //         },
      //         {
      //           yAxis: 70
      //         }
      //       ]
      //     ]
      //   }
      // },
      {
        name: "STOCH FAST",
        type: "line",
        symbol: "none",
        xAxisIndex: 1,
        yAxisIndex: 2,
        itemStyle: {
          color: "#7fbe9e",
        },
        large: true,
        encode: {
          x: index.date,
          y: index.stoch_fast,
        },
        markArea: {
          data: [
            [
              {
                yAxis: 20,
              },
              {
                yAxis: 80,
              },
            ],
          ],
        },
      },
      {
        name: "STOCH SLOW",
        type: "line",
        symbol: "none",
        xAxisIndex: 1,
        yAxisIndex: 2,
        itemStyle: {
          color: "#81aabe",
        },
        large: true,
        encode: {
          x: index.date,
          y: index.stoch_slow,
        },
      },
      {
        name: "Exit price",
        type: "line",
        symbol: "none",
        lineStyle: {
          color: "green",
          width: 1,
          opacity: 0.5,
        },
        encode: {
          x: index.date,
          y: index.targetPrice,
        },
      },
      {
        name: "Stop loss",
        type: "line",
        symbol: "none",
        lineStyle: {
          color: "red",
          width: 1,
          opacity: 0.5,
        },
        encode: {
          x: index.date,
          y: index.stopLoss,
        },
      },
    ],
  };
}
