import { IChartingLibraryWidget, IChartWidgetApi } from "@/plugins/tv_charting_library/charting_library";
import { Events } from "@/service/tv-charting-library/chart-api/events";
import { Cache } from "@/service/tv-charting-library/chart-api/cache";
import { Positions } from "@/service/tv-charting-library/chart-api/positions";
import { ChartUtils } from "@/service/tv-charting-library/chart-api/chart-utils";
import { IndicatorConfigApi } from "@/service/tv-charting-library/chart-api/indicator-config-api";
import { ButtonsApi } from "@/service/tv-charting-library/chart-api/buttons-api";
import { IntervalChangeHandler } from "@/service/tv-charting-library/chart-api/interval-change-handler";

export class ChartApi {
  readonly events: Events;
  readonly cache: Cache;
  readonly positions: Positions;
  readonly entryIndicatorConfigApi: IndicatorConfigApi;
  readonly exitIndicatorConfigApi: IndicatorConfigApi;
  readonly buttonsApi: ButtonsApi;

  constructor(protected tvWidget: IChartingLibraryWidget, protected tvChart: IChartWidgetApi) {
    this.events = new Events(tvChart);
    this.cache = new Cache(tvChart, this);
    this.positions = new Positions(tvWidget, tvChart, this, this.events, this.cache);
    this.entryIndicatorConfigApi = new IndicatorConfigApi(tvChart);
    this.exitIndicatorConfigApi = new IndicatorConfigApi(tvChart);
    this.buttonsApi = new ButtonsApi(tvWidget, tvChart, this.entryIndicatorConfigApi, this.exitIndicatorConfigApi);

    this.init();
  }

  private init() {
    //NOTE: Do faster _unsubscribeRealtime and _purgeCache on interval change.
    //      We need to set here also, so when we first change interval it already knows
    const datafeedThread = this.cache.getDatafeedThread();
    datafeedThread._resetCacheTimePeriod = IntervalChangeHandler.unsubscribeTimeout;
  }

  getTicker() {
    return this.getSymbolInfo().ticker;
  }

  getInterval() {
    return this.tvChart.resolution();
  }

  getSymbolInfo() {
    return this.tvChart._chartWidget.model().mainSeries().symbolInfo();
  }

  getIntervalInSec(interval?: string) {
    if (!interval) {
      interval = this.tvChart.resolution();
    }
    return ChartUtils.convertIntervalToSec(interval);
  }

  getIntervalInMs(interval?: string) {
    return this.getIntervalInSec(interval) * 1000;
  }

  resample(value: number) {
    const intervalInSec = this.getIntervalInSec();
    return ChartUtils.resample(value, intervalInSec);
  }

  nowResampled() {
    const intervalInSec = this.getIntervalInSec();
    return ChartUtils.nowResampled(intervalInSec);
  }

  destroy() {
    this.positions.destroy();
  }
}
