
import BackTestFilters from "@/components/BackTestFilters.vue";
import BackTestChart from "@/components/BackTestChart.vue";
import BackTestChartGeneral from "@/components/BackTestChartGeneral.vue";
import ProfitLoss from "@/components/ProfitLoss.vue";
import PositionHistory from "@/components/PositionHistory.vue";
import Component from "vue-class-component";
import Vue from "vue";
import {
  IndicatorConfig,
  Position,
  StrategyRun,
  StrategyRunGetOneRequest,
  SupportResistancePrice,
} from "next-trader-api";
import { botApi, strategyRunApi } from "@/plugins/api";
import { map, switchMap, filter } from "rxjs/operators";
import TVChartContainer from "@/components/StrategyRun/TVChartContainer.vue";
import Ooops from "@/components/Ooops.vue";
import { Subscription, timer } from "rxjs";
import dayjs from "dayjs";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import { LiveDatafeed } from "@/service/tv-charting-library/live-datafeed";
import { MyBaseDatafeed } from "@/service/tv-charting-library/my-base-datafeed";
import BackTestTemplate from "@/views/BackTestTemplate.vue";

@Component({
  components: {
    BackTestTemplate,
    Ooops,
    BackTestFilters,
    BackTestChart,
    BackTestChartGeneral,
    ProfitLoss,
    PositionHistory,
    TVChartContainer,
    Splitpanes,
    Pane,
  },
})
export default class BackTest extends Vue {
  strategyRunId = this.$router.currentRoute.params.strategyRunId;

  tab = 0;
  strategyRun: StrategyRun | null = null;
  processedUntil = 0;

  hodlChartData: any = null;

  subscription?: Subscription;

  datafeed?: MyBaseDatafeed;

  showTvChart = false;

  activePosition?: Position | null = null;

  entryIndicatorConfigs: Array<IndicatorConfig> = [];
  exitIndicatorConfigs: Array<IndicatorConfig> = [];
  supportResistancePrices: Array<SupportResistancePrice> = [];

  lastPositionEntryDate?: string; //TODO Value should be timestamp instead of string, so that summer/winter time change will work
  openPositionIds: number[] = [];

  created() {
    this.initStrategyRun();
    this.getHodlChartData$().subscribe();
  }

  destroyed() {
    this.subscription?.unsubscribe();
    this.datafeed?.destroy();
  }

  initStrategyRun() {
    strategyRunApi
      .postAppStrategyrunGetone({
        strategyRunId: this.strategyRunId,
      })
      .pipe(
        map((next) => {
          this.strategyRun = next;
          if (this.strategyRun.positions !== undefined) {
            this.updateLastPositionEntryDate(this.strategyRun.positions);
          }
        }),
        switchMap(() => {
          return strategyRunApi.getAppStrategyrunGetindicatorconfigs({
            strategyRunId: this.strategyRunId,
          });
        }),
        map((next) => {
          this.entryIndicatorConfigs = next.entryIndicatorConfigs;
          this.exitIndicatorConfigs = next.exitIndicatorConfigs;
        }),
        switchMap(() => {
          return this.getSupportResistancePrices$();
        })
      )
      .subscribe(() => {
        if (!this.strategyRun) {
          return;
        }

        if (this.strategyRun.isBackTesting) {
          const maxEndDate = dayjs.utc(this.strategyRun.dateTo).unix();
          this.datafeed = new MyBaseDatafeed(maxEndDate);
        } else {
          this.datafeed = new LiveDatafeed();
        }

        this.showTvChart = true;

        if (this.strategyRun.isBackTesting) {
          //this.subscription = this.getStrategyRunStrategyRunIdGetForBacktest$().subscribe();
        } else {
          this.subscription = this.getStrategyRunStrategyRunIdGetForLive$().subscribe();
        }
      });
  }

  getSupportResistancePrices$() {
    return strategyRunApi
      .getAppStrategyrunGetsupportresistanceprices({
        strategyRunId: this.strategyRunId,
      })
      .pipe(
        map((next) => {
          this.supportResistancePrices = next;
        })
      );
  }

  // getStrategyRunStrategyRunIdGetForBacktest$() {
  //   const lastBarTime = dayjs.utc(this.strategyRun?.dateTo).unix();
  //   //const datafeedOptions = new BotRunnerDatafeedOptions(lastBarTime, new Subject());
  //   //this.datafeedOptions = datafeedOptions;
  //   this.processedUntil = lastBarTime;
  //
  //   return timer(0, 1000).pipe(
  //     switchMap(() => {
  //       return strategyRunApi
  //         .apiStrategyRunStrategyRunIdToGet({
  //           strategyRunId: this.strategyRun?.id,
  //           to: this.processedUntil
  //         })
  //         .pipe(
  //           map(next => {
  //             this.strategyRun = next;
  //
  //             const processedUntil = dayjs.utc(this.strategyRun?.processedUntil).unix();
  //             if (this.processedUntil + 3600 <= processedUntil) {
  //               this.processedUntil += 3600;
  //               //datafeedOptions.updateBarsUntilTimeStream.next(this.processedUntil);
  //             }
  //             this.showTvChart = true;
  //
  //             return next;
  //           })
  //         );
  //     })
  //   );
  // }

  private updateLastPositionEntryDate(positions: Position[]) {
    this.openPositionIds = [];
    for (const position of positions) {
      if (!position.isClosed && position.id !== undefined) {
        this.openPositionIds.push(position.id);
      }
      if (position.entryDate === undefined) {
        continue;
      }
      if (this.lastPositionEntryDate === undefined || position.entryDate > this.lastPositionEntryDate) {
        this.lastPositionEntryDate = position.entryDate;
      }
    }
  }

  private getStrategyRunStrategyRunIdGetForLive$() {
    return timer(0, 1000).pipe(
      filter(() => this.strategyRun?.isRunning || false),
      switchMap(() => {
        const request: StrategyRunGetOneRequest = {
          openPositionIds: this.openPositionIds || [],
        };
        if (this.lastPositionEntryDate) {
          request.from = this.lastPositionEntryDate;
        }
        return strategyRunApi.postAppStrategyrunGetone({
          strategyRunId: this.strategyRunId,
          strategyRunGetOneRequest: request,
        });
      }),
      map((next) => {
        const updatedPositions = next.positions;
        next.positions = this.strategyRun?.positions;
        this.strategyRun = next;
        if (updatedPositions) {
          this.updateLastPositionEntryDate(updatedPositions);
          this.updateOpenPositions(updatedPositions);
        }

        //const processedUntil = this.strategyRun?.processedUntil
        //  ? dayjs.utc(this.strategyRun?.processedUntil).unix()
        //  : dayjs.utc().unix();

        //if (liveDatafeedOptions.lastBarTime === 0) {
        //  liveDatafeedOptions.lastBarTime = processedUntil;
        //}
        //liveDatafeedOptions.updateBarsUntilTimeStream.next(processedUntil);
        //this.showTvChart = true;

        return next;
      })
    );
  }

  private updateOpenPositions(updatedPositions: Position[]) {
    if (this.strategyRun === null || this.strategyRun.positions === undefined) {
      return;
    }

    for (const updatedPosition of updatedPositions) {
      const index = this.strategyRun?.positions?.findIndex((position) => position.id === updatedPosition.id);
      if (index !== -1) {
        this.strategyRun.positions.splice(index, 1, updatedPosition);
      } else {
        this.strategyRun.positions.push(updatedPosition);
      }
    }
  }

  getHodlChartData$() {
    return strategyRunApi
      .getAppStrategyrunGethodlchartdata({
        strategyRunId: this.$router.currentRoute.params.strategyRunId,
      })
      .pipe(
        map((next) => {
          this.hodlChartData = next;
          return next;
        })
      );
  }

  startBot() {
    botApi.postAppBotStart({ strategyRunId: this.strategyRun?.id || 0 }).subscribe(() => {
      if (!this.strategyRun) {
        return;
      }
      this.strategyRun.isRunning = true;
    });
  }

  stopBot() {
    botApi.postAppBotStop({ strategyRunId: this.strategyRun?.id || 0 }).subscribe(() => {
      if (!this.strategyRun) {
        return;
      }
      this.strategyRun.isRunning = false;
    });
  }

  clickPosition(position: Position) {
    if (this.activePosition && this.activePosition.id === position.id) {
      this.activePosition = undefined;
    } else {
      this.activePosition = position;
    }

    const tvChartContainer: TVChartContainer = this.$refs.tvChart as TVChartContainer;
    tvChartContainer.setActivePosition(this.activePosition);
  }

  saveSupportResistancePrices($event: any) {
    const supportResistancePrices = $event.map((price: any) => {
      const srp: SupportResistancePrice = { price: price };
      return srp;
    });

    strategyRunApi
      .postAppStrategyrunSavesupportresistanceprices({
        strategyRunId: this.strategyRunId,
        saveSupportResistancePricesRequest: {
          supportResistancePrices: supportResistancePrices,
        },
      })
      .subscribe();
  }
}
