import { RequestParams, UdfResponse, UdfErrorResponse, logMessage } from "@/plugins/tv_datafeeds/helpers";
import firebase from "@/plugins/firebase";
import { Requester } from "@/plugins/tv_datafeeds/requester";

export class MyRequester extends Requester {
  private readonly __headers: HeadersInit | undefined;

  public constructor(headers?: HeadersInit) {
    super(headers);
    if (headers) {
      this.__headers = headers;
    }
  }

  public sendRequest<T extends UdfResponse>(
    datafeedUrl: string,
    urlPath: string,
    params?: RequestParams
  ): Promise<T | UdfErrorResponse>;
  public sendRequest<T>(datafeedUrl: string, urlPath: string, params?: RequestParams): Promise<T>;
  public sendRequest<T>(datafeedUrl: string, urlPath: string, params?: RequestParams): Promise<T> {
    if (params !== undefined) {
      const paramKeys = Object.keys(params);
      if (paramKeys.length !== 0) {
        urlPath += "?";
      }

      urlPath += paramKeys
        .map((key: string) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(params[key].toString())}`;
        })
        .join("&");
    }

    logMessage("New request: " + urlPath);

    // Send user cookies if the URL is on the same origin as the calling script.
    const options: RequestInit = { credentials: "same-origin" };

    if (this.__headers !== undefined) {
      options.headers = this.__headers;
    }

    return firebase.getIdToken().then((idToken) => {
      if (idToken) {
        options.headers = { ...options.headers, "X-AUTH-TOKEN": idToken };
      }

      return fetch(`${datafeedUrl}/${urlPath}`, options)
        .then((response: Response) => response.text())
        .then((responseTest: string) => JSON.parse(responseTest));
    });
  }
}
