// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PineJS } from "@/plugins/tv_charting_library/charting_library";

/* eslint-disable */

export const entryHandlerIndicator = (PineJS: PineJS) => {
  return {
    // Replace the <study name> with your study name
    // The name will be used internally by the Charting Library
    name: "entry_handler_indicator",
    metainfo: {
      _metainfoVersion: 40,
      id: "entry_handler_indicator@tv-basicstudies-1",
      scriptIdPart: "",
      name: "entry_handler_indicator",

      // This description will be displayed in the Indicators window
      // It is also used as a "name" argument when calling the createStudy method
      description: "Entry Handler Indicator",

      // This description will be displayed on the chart
      shortDescription: "Entry Handler Indicator",

      // eslint-disable-next-line @typescript-eslint/camelcase
      is_hidden_study: true,
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_price_study: true,
      isCustomIndicator: true,

      plots: [
        { id: "plot_0", type: "lineWithBrakes" },
        { id: "plot_1", type: "lineWithBrakes" },
      ],
      defaults: {
        styles: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          plot_0: {
            linestyle: 0,
            visible: true,
            // Plot line width.
            linewidth: 8,
            // Plot type:
            //    1 - Histogram
            //    2 - Line
            //    3 - Cross
            //    4 - Area
            //    5 - Columns
            //    6 - Circles
            //    7 - Line With Breaks
            //    8 - Area With Breaks
            plottype: 7,
            // Show price line?
            trackPrice: false,
            // Plot transparency, in percent.
            transparency: 50,
            // Plot color in #RRGGBB format
            color: "blue",
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          plot_1: {
            linestyle: 0,
            visible: true,
            linewidth: 8,
            plottype: 7,
            trackPrice: false,
            transparency: 50,
            color: "orange",
          },
        },

        // Precision of the study's output values
        // (quantity of digits after the decimal separator).
        precision: 2,
        inputs: {
          in_0: "",
          in_1: "",
          in_2: "",
          in_3: "",
          in_4: "",
          in_5: "",
          in_6: "",
          in_7: "",
          in_8: "",
          in_9: "",
        },
      },
      styles: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        plot_0: {
          // Output name will be displayed in the Style window
          title: "-- output name --",
          histogramBase: 0,
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        plot_1: {
          // Output name will be displayed in the Style window
          title: "-- output name --",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "in_0",
          name: "Input 1",
          type: "string",
        },
        {
          id: "in_1",
          name: "Input 2",
          type: "string",
        },
        {
          id: "in_2",
          name: "Input 3",
          type: "string",
        },
        {
          id: "in_3",
          name: "Input 4",
          type: "string",
        },
        {
          id: "in_4",
          name: "Input 5",
          type: "string",
        },
        {
          id: "in_5",
          name: "Input 6",
          type: "string",
        },
        {
          id: "in_6",
          name: "Input 7",
          type: "string",
        },
        {
          id: "in_7",
          name: "Input 8",
          type: "string",
        },
        {
          id: "in_8",
          name: "Input 9",
          type: "string",
        },
        {
          id: "in_9",
          name: "Input 10",
          type: "string",
        },
      ],
    },

    constructor: function () {
      // @ts-ignore
      this.init = function (context: any, inputCallback: any) {
        // @ts-ignore
        this._context = context;
        // @ts-ignore
        this._input = inputCallback;

        // Define the symbol to be plotted.
        // Symbol should be a string.
        // You can use PineJS.Std.ticker(this._context) to get the selected symbol's ticker.
        // For example,
        //    var symbol = "AAPL";
        //    var symbol = "#EQUITY";
        // @ts-ignore
        const symbol =
          "#ENTRY_HANDLER[" +
          // @ts-ignore
          this._input(0) +
          "," +
          // @ts-ignore
          this._input(1) +
          "," +
          // @ts-ignore
          this._input(2) +
          "," +
          // @ts-ignore
          this._input(3) +
          "," +
          // @ts-ignore
          this._input(4) +
          "," +
          // @ts-ignore
          this._input(5) +
          "," +
          // @ts-ignore
          this._input(6) +
          "," +
          // @ts-ignore
          this._input(7) +
          "," +
          // @ts-ignore
          this._input(8) +
          "," +
          // @ts-ignore
          this._input(9) +
          "]@" +
          // @ts-ignore
          PineJS.Std.ticker(this._context).replace('"', "").replace('"}', "");

        // @ts-ignore
        this._context.new_sym(
          symbol,
          // @ts-ignore
          PineJS.Std.period(this._context),
          // @ts-ignore
          PineJS.Std.period(this._context)
        );
      };

      // @ts-ignore
      this.main = function (context: any, inputCallback: any) {
        // @ts-ignore
        this._context = context;
        // @ts-ignore
        this._input = inputCallback;

        // @ts-ignore
        this._context.select_sym(1);

        // You can use following built-in functions in PineJS.Std object:
        //    open, high, low, close
        //    hl2, hlc3, ohlc4
        // @ts-ignore
        const open = PineJS.Std.open(this._context);
        // @ts-ignore
        const high = PineJS.Std.high(this._context);
        return [open, high];
      };
    },
  };
};
