import { Observable } from "rxjs";
import {
  IChartWidgetApi,
  ResolutionString,
  TimeFrameValue,
  VisibleTimeRange,
} from "@/plugins/tv_charting_library/charting_library";

export declare type IntervalChangedParams = {
  interval: ResolutionString;
  timeFrameParameters: TimeFrameParameters;
};

export declare type TimeFrameParameters = {
  timeframe?: TimeFrameValue;
};

export class Events {
  constructor(protected tvChart: IChartWidgetApi) {}

  onVisibleRangeChanged() {
    return new Observable<VisibleTimeRange>((subscriber) => {
      const handler = (range: VisibleTimeRange) => {
        subscriber.next(range);
      };
      this.tvChart.onVisibleRangeChanged().subscribe(null, handler);
      subscriber.next(this.tvChart.getVisibleRange());

      return () => {
        this.tvChart.onVisibleRangeChanged().unsubscribe(null, handler);
      };
    });
  }

  onZoom() {
    return new Observable<{ zoomValue: number; event: WheelEvent }>((subscriber) => {
      this.tvChart._chartWidget.onZoom().subscribe(this, (ctrlKey, zoomValue, event) => {
        subscriber.next({ zoomValue, event });
      });
    });
  }

  onIntervalChanged() {
    return new Observable<IntervalChangedParams>((subscriber) => {
      const handler = (
        interval: ResolutionString,
        timeFrameParameters: {
          timeframe?: TimeFrameValue;
        }
      ) => {
        subscriber.next({ interval: interval, timeFrameParameters: timeFrameParameters });
      };
      this.tvChart.onIntervalChanged().subscribe(null, handler);

      return () => {
        this.tvChart.onIntervalChanged().unsubscribe(null, handler);
      };
    });
  }
}
