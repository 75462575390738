/* eslint-disable */

export const testIndicator = (PineJS) => {
  return {
    name: "Test Moving Average",
    metainfo: {
      _metainfoVersion: 52,
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      is_price_study: false,
      defaults: {
        styles: {
          plot_0: {
            linestyle: 0,
            linewidth: 1,
            plottype: 6,
            trackPrice: !1,
            transparency: 0,
            visible: !0,
            color: "#2196F3",
          },
          plot_1: {
            linestyle: 0,
            linewidth: 1,
            plottype: 6,
            trackPrice: !1,
            transparency: 0,
            visible: !0,
            color: "red",
          },
          plot_2: {
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: !1,
            transparency: 0,
            visible: !0,
            color: 'rgba(0,128,0,0.5)',
          },
          plot_3: {
            isHidden: false,
            location: 'Absolute',
            plottype: 'shape_circle',
            size: 'small',
            title: 'Shapes'
          },
        },
        inputs: { in_0: 9 },
      },
      plots: [
        { id: "plot_0", type: "line" },
        { id: "plot_1", type: "line" },
        { id: "plot_2", type: "line" },
        { id: "plot_3", type: "shapes" },
      ],
      styles: { plot_0: { title: "Plot", histogramBase: 0, joinPoints: !1 } },
      description: "Test Moving Average",
      shortDescription: "TESTMA",
      inputs: [{ id: "in_0", name: "Length", defval: 9, type: "integer", min: 1, max: 1e4 }],
      id: "Test Moving Average@tv-basicstudies-1",
      scriptIdPart: "",
      name: "Test Moving Average",
      format: { type: "inherit" },
    },
    constructor: function () {
      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const length = this._input(0);

        const volume = PineJS.Std.volume(this._context);
        const close = PineJS.Std.close(this._context);
        const open = PineJS.Std.open(this._context);
        const priceChange = Math.abs(close - open);

        const volumeSeries = this._context.new_var(volume);
        const priceChangeSeries = this._context.new_var(priceChange);

        const smaVolume = PineJS.Std.sma(volumeSeries, length, this._context);
        const smaPriceChange = PineJS.Std.sma(priceChangeSeries, length, this._context);
        const maxVolume = Math.max.apply(null, volumeSeries.hist);

        const smaVolumeSeries = this._context.new_var(smaVolume);
        const smaPriceChangeSeries = this._context.new_var(smaPriceChange);
        const maxVolumeSeries = this._context.new_var(maxVolume);

        const volumePrev = volumeSeries.get(1);

        //console.log(newSource.get(), newSource.get(10));
        //console.log(smaVolume);
        //return [smaVolumeSeries.get(1), smaPriceChangeSeries.get(1), maxVolumeSeries.get(1)];

        const limit = 10;
        const value1 = volume / smaVolumeSeries.get(2);
        const value2 = volumePrev / smaVolumeSeries.get(2);
        const circlePos = value1 > limit && value2 > limit ? (value1 + value2) / 2 : NaN;

        const limitToDisplay = 1.5;

        return [
          value1 > limitToDisplay ? value1 : NaN,
          value2 > limitToDisplay ? value2 : NaN,
          limit,
          circlePos
        ];
      };
    },
  };
};
