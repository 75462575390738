
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component
export default class Ooops extends Vue {
  @Prop()
  title?: string;

  @Prop({ required: true })
  text?: string;

  @Prop({ required: true })
  animal?: string;

  logoName?: string;

  getColor() {
    // if (this.logoName == "donkey") {
    //   return "rgb(62 62 62)";
    // }
    // if (this.logoName == "whale") {
    //   return "rgb(64 97 140)";
    // }
    // if (this.logoName == "octopus") {
    //   return "rgb(85 59 142)";
    // }
    // if (this.logoName == "tropical-fish") {
    //   return "rgb(215 103 27)";
    // }
    // if (this.logoName == "parrot") {
    //   return "rgb(203 43 43)";
    // }
    //
    return this.colors[this.logoName] || "#455A64";
  }

  getIconUrl() {
    return require("@/assets/animal-icons-squared/png/" + this.logoName + "-icon.png");
  }

  created() {
    this.logoName = this.animal;
    if (this.logoName == "random") {
      const i = Math.floor(Math.random() * this.animalNames.length);
      this.logoName = this.animalNames[i];
    }
  }

  animalNames: Array<string> = [
    "Bat",
    "Bear",
    "Beaver",
    "Bee",
    "Bull",
    "Cat",
    "Chicken",
    "Cow",
    "Crab",
    "Crocodile",
    "Deer",
    "Dog",
    "Dolphin",
    "Duck",
    "Eagle",
    "Elephant",
    "Fish",
    "Frog",
    "Giraffe",
    "Goat",
    "Gorilla",
    "Hippo",
    "Horse",
    "Kangaroo",
    "Koala",
    "Lion",
    "Lizard",
    "Lobster",
    "Monkey",
    "Mouse",
    "Octopus",
    "Owl",
    "Penguin",
    "Pig",
    "Rabbit",
    "Raccoon",
    "Rat",
    "Rhino",
    "Seal",
    "Shark",
    "Sheep",
    "Snail",
    "Snake",
    "Squirrel",
    "Swan",
    "Tiger",
    "Tuna",
    "Turtle",
    "Whale",
    "Wolf",
    // "bird",
    // "black-cat",
    // "bulldog",
    // "bunny",
    // "cardinal",
    // "cat",
    // "chicken",
    // "chihuahua",
    // "cow",
    // "crab",
    // "dachshund",
    // "dog",
    // "dolphin",
    // "donkey",
    // "duck",
    // "fish",
    // "frog",
    // "gold-fish",
    // "hamster",
    // "horse",
    // "jellyfish",
    // "kitten",
    // "lobster",
    // "mouse",
    // "octopus",
    // "parrot",
    // "pig",
    // "pony",
    // "puppy",
    // "rabbit",
    // "seal",
    // "shark",
    // "sheep",
    // "squid",
    // "squirrel",
    // "tropical-fish",
    // "tuna",
    // "turkey",
    // "turtle",
    // "whale",
  ];

  colors = {
    donkey: "rgb(62 62 62)",
    whale: "rgb(64 97 140)",
    octopus: "rgb(85 59 142)",
    "tropical-fish": "rgb(215 103 27)",
    parrot: "rgb(203 43 43)",
    chihuahua: "rgb(139 109 78)",
    chicken: "rgb(218 118 13)",
    cardinal: "rgb(198 31 31)",
    fish: "rgb(40 104 42)",
    squirrel: "rgb(106 77 59)",
    turtle: "rgb(98 112 68)",
    crab: "rgb(214 45 15)",
    mouse: "rgb(210 86 131)",
    pig: "rgb(182 115 151)",
    horse: "rgb(162 127 87)",
    kitten: "rgb(154 106 55)",
    rabbit: "rgb(120 94 76)",
    dachshund: "rgb(118 68 25)",
    hamster: "rgb(192 140 82)",
    pony: "rgb(145 84 111)",
    cow: "rgb(145 98 63)",
    dolphin: "rgb(97 118 142)",
    jellyfish: "rgb(105 69 132)",
    lobster: "rgb(214 45 15)",
    frog: "rgb(91 112 45)",
    squid: "rgb(157 90 181)",
    bird: "rgb(8 134 217)",
    turkey: "rgb(124 77 35)",
    "gold-fish": "rgb(190 109 33)",
    shark: "rgb(78 103 117)",
    dog: "rgb(142 111 80)",
    duck: "rgb(185 154 21)",
  };
}
