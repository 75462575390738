export default (PineJS) => {
  return {
    name: "Price Change",
    metainfo: {
      _metainfoVersion: 52,
      shortDescription: "Price Change",
      name: "Price Change",
      description: "Price Change",
      id: "Price Change@tv-basicstudies-1",
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      is_price_study: false,
      defaults: {
        styles: {
          plot_0: {
            linestyle: 0,
            linewidth: 1,
            plottype: 5,
            trackPrice: !1,
            transparency: 0,
            visible: !0,
            color: "#2196F3",
          },
        },
        palettes: {
          paletteId1: {
            colors: {
              0: {
                color: "#2196F3",
                width: 1,
                style: 0,
              },
              1: {
                color: "#eee",
                width: 1,
                style: 0,
              },
            },
          },
        },
        inputs: { in_0: 5, in_1: 2 },
      },
      plots: [
        { id: "plot_0", type: "line" },
        { id: "plot_1", type: "colorer", target: "plot_0", palette: "paletteId1" },
      ],
      palettes: {
        paletteId1: {
          colors: {
            0: {
              name: "First color",
            },
            1: {
              name: "Second color",
            },
          },
        },
      },
      styles: {
        plot_0: { title: "Oscillator", histogramBase: 0, joinPoints: !1 },
      },
      inputs: [
        { id: "in_0", name: "Length", defval: 5, type: "integer", min: 1, max: 2e3 },
        { id: "in_1", name: "Minimum diff percent", defval: 2, type: "integer", min: 1, max: 2e3 },
      ],
      scriptIdPart: "",
      format: { type: "price", precision: 2 },
    },
    constructor: function () {
      const openCloseDiff = (length, offset) => {
        const open = PineJS.Std.open(this._context);
        const close = PineJS.Std.close(this._context);

        const seriesOpen = this._context.new_var(open);
        const seriesClose = this._context.new_var(close);

        seriesOpen.get(length + offset - 1); //Note: This is necessary to store {length} items in ".hist"
        seriesClose.get(offset); //Note: This is necessary to store {length} items in ".hist"

        const firstOpen = seriesOpen.hist ? seriesOpen.hist[0] : 0;
        const lastClose = seriesClose.hist ? seriesClose.hist[0] : 0;

        return lastClose - firstOpen;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const currentLength = this._input(0);
        const minDiff = this._input(1);

        const testValue = openCloseDiff(currentLength, 0);

        return [testValue, testValue >= minDiff ? 0 : 1];
      };
    },
  };
};
