/* eslint-disable */

export default (PineJS) => {
  return {
    name: "Volume Above Average",
    metainfo: {
      _metainfoVersion: 52,
      shortDescription: "VOL AB AVG",
      name: "Volume Above Average",
      description: "Volume Above Average",
      id: "Volume Above Average@tv-basicstudies-1",
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      is_price_study: false,
      defaults: {
        styles: {
          plot_0: {
            linestyle: 0,
            linewidth: 1,
            plottype: 5,
            trackPrice: false,
            transparency: 0,
            visible: true,
            color: "#2196F3",
          },
          plot_1: {
            linestyle: 2,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0,
            visible: true,
            color: 'rgba(0,128,0,0.5)',
          },
        },
        inputs: { in_0: 50, in_1: 10, in_2: 1 },
      },
      plots: [
        { id: "plot_0", type: "line" },
        { id: "plot_1", type: "line" },
      ],
      inputs: [
        { id: "in_0", name: "Length", defval: 50, type: "integer", min: 1, max: 1e4 },
        { id: "in_1", name: "Limit", defval: 10, type: "float", min: 1, max: 1e4 },
        { id: "in_2", name: "Offset", defval: 1, type: "integer", min: 1, max: 1e4 },
      ],
      scriptIdPart: "",
      format: { type: "inherit" },
    },
    constructor: function () {
      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const length = this._input(0);
        const limit = this._input(1);
        const offset = this._input(2);

        const volume = PineJS.Std.volume(this._context);
        const volumeSeries = this._context.new_var(volume);
        const smaVolume = PineJS.Std.sma(volumeSeries, length, this._context);
        const smaVolumeSeries = this._context.new_var(smaVolume);

        return [
          volume / smaVolumeSeries.get(offset),
          limit,
        ];
      };
    },
  };
};
