
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { Filters, Market } from "api/models";
import { mdiCalendar } from "@mdi/js";

@Component
export default class BackTestFilters extends Vue {
  menuFrom: any = null;
  menuTo: any = null;

  @Prop({ required: true })
  markets?: Array<Market>;

  @Prop({ required: true })
  filters?: Filters;

  icons = {
    mdiCalendar: mdiCalendar,
  };

  doFilter() {
    this.$emit("filter", this.filters);
  }
}
