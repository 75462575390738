import { EntityId, IChartWidgetApi, StudyOverrides } from "@/plugins/tv_charting_library/charting_library";
import { IndicatorConfig } from "next-trader-api";

export class IndicatorConfigApi {
  constructor(protected tvChart: IChartWidgetApi) {}

  indicatorConfigs: Array<IndicatorConfig> = [];
  entityIds: Array<EntityId> = [];
  display = false;

  addMany(indicatorConfigs: IndicatorConfig[]) {
    this.indicatorConfigs = indicatorConfigs;
    if (this.display) {
      this.showAll();
    }
  }

  clear() {
    this.hideAll();
    this.indicatorConfigs = [];
  }

  toggleAll() {
    this.display = !this.display;

    if (this.display) {
      this.showAll();
    } else {
      this.hideAll();
    }
  }

  showAll() {
    for (const indicatorConfig of this.indicatorConfigs) {
      this.createIndicator(indicatorConfig);
    }
  }

  hideAll() {
    for (const entityId of this.entityIds) {
      this.tvChart.removeEntity(entityId);
    }
    this.entityIds = [];
  }

  private createIndicator(indicatorConfig: IndicatorConfig) {
    this.tvChart
      .createStudy(
        indicatorConfig.name || "",
        false,
        false,
        indicatorConfig.inputs,
        indicatorConfig.overrides as StudyOverrides,
        indicatorConfig.options
      )
      .then((entityId) => {
        if (entityId) {
          this.entityIds.push(entityId);
        }
      });
  }
}
