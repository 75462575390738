export default class Utils {
  static sigmoid = (value, multiplier) => {
    return 1 / (1 + Math.exp(-value * multiplier));
  };

  static modifiedSigmoid = (value, multiplier) => {
    if (value >= 1) {
      return Utils.sigmoid(value - 1, multiplier);
    } else {
      return Utils.sigmoid(-1 / value + 1, multiplier);
    }
  };

  static arrAvg = (arr) => {
    return arr !== undefined ? arr.reduce((a, b) => (a + b) / arr.length, 0) : 0;
  };
  static arrSum = (arr) => {
    return arr !== undefined ? arr.reduce((a, b) => a + b, 0) : 0;
  };
}
