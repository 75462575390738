import {
  ErrorCallback,
  HistoryCallback,
  LibrarySymbolInfo,
  ResolutionString,
  SubscribeBarsCallback,
} from "@/plugins/tv_charting_library/datafeed-api";
import { PeriodParamsWithOptionalCountback } from "@/plugins/tv_datafeeds/history-provider";
import { MyUDFCompatibleDatafeed } from "@/service/tv-charting-library/my-udf-compatible-datafeed";
import { ChartApi } from "@/service/tv-charting-library/chart-api/chart-api";

export class MyBaseDatafeed extends MyUDFCompatibleDatafeed {
  protected onResetCacheNeededCallbacks: Map<string, (endDate?: number, startDate?: number) => void> = new Map();

  protected endDate?: number;
  protected chartApi?: ChartApi;

  constructor(protected maxEndDate?: number) {
    super();
    this.endDate = maxEndDate;
  }

  setChartApi(chartApi: ChartApi) {
    this.chartApi = chartApi;
  }

  setEndDate(endDate?: number) {
    if (this.maxEndDate && (endDate === undefined || endDate > this.maxEndDate)) {
      endDate = this.maxEndDate;
    }
    this.endDate = endDate;
  }

  getEndDate() {
    return this.endDate;
  }

  setMaxEndDate(maxEndDate: number) {
    this.maxEndDate = maxEndDate;
    this.setEndDate(maxEndDate);
  }

  getMaxEndDate() {
    return this.maxEndDate;
  }

  resetData(ticker: string, interval: string) {
    this.setEndDate(undefined);

    const callback = this.getOnResetCacheNeededCallback(ticker, interval);
    callback();
  }

  resetDataByRange(ticker: string, interval: string, endDate: number, startDate: number) {
    this.setEndDate(endDate);

    const callback = this.getOnResetCacheNeededCallback(ticker, interval);
    callback(endDate, startDate);
  }

  private getOnResetCacheNeededCallback(ticker: string, interval: string) {
    const key = ticker + "@" + interval;
    const callback = this.onResetCacheNeededCallbacks.get(key);
    if (!callback) {
      throw "onResetCacheNeededCallback is undefined for '" + key + "', subscribeBars is not called yet!";
    }
    return callback;
  }

  getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParamsWithOptionalCountback,
    onResult: HistoryCallback,
    onError: ErrorCallback
  ): void {
    if (this.endDate) {
      //If both from and to are in the future we return no data and ask for data until endDate
      if (periodParams.to > this.endDate && periodParams.from > this.endDate) {
        onResult([], {
          noData: true,
          nextTime: this.endDate,
        });
        return;
      }

      //If from parameter is OK but to is in the future we ask until requestUntil only
      if (periodParams.to > this.endDate) {
        periodParams.to = this.endDate;
      }
    }

    return super.getBars(symbolInfo, resolution, periodParams, onResult, onError);
  }

  subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: (endDate?: number, startDate?: number) => void
  ): void {
    const ticker = symbolInfo.ticker;
    this.onResetCacheNeededCallbacks.set(ticker + "@" + resolution, onResetCacheNeededCallback);

    return super.subscribeBars(symbolInfo, resolution, onTick, listenerGuid, onResetCacheNeededCallback);
  }
}
