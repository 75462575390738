/** [ timestamp, open, high, low, close, volume, fistTradeId, lastTradeId, isOpen ] */
import { Bar } from "@/plugins/tv_charting_library/datafeed-api";

export type OhlcvCandleData = [number, number, number, number, number, number, number, number, boolean];

const INDEX_TIMESTAMP = 0;
const INDEX_OPEN = 1;
const INDEX_HIGH = 2;
const INDEX_LOW = 3;
const INDEX_CLOSE = 4;
const INDEX_VOLUME = 5;
const INDEX_FIRST_TRADE_ID = 6;
const INDEX_LAST_TRADE_ID = 7;
const INDEX_IS_OPEN = 8;

export const indexes = {
  INDEX_TIMESTAMP: INDEX_TIMESTAMP,
  INDEX_OPEN: INDEX_OPEN,
  INDEX_HIGH: INDEX_HIGH,
  INDEX_LOW: INDEX_LOW,
  INDEX_CLOSE: INDEX_CLOSE,
  INDEX_VOLUME: INDEX_VOLUME,
  INDEX_FIRST_TRADE_ID: INDEX_FIRST_TRADE_ID,
  INDEX_LAST_TRADE_ID: INDEX_LAST_TRADE_ID,
  INDEX_IS_OPEN: INDEX_IS_OPEN,
};

export default class OhlcvCandle {
  constructor(private data: OhlcvCandleData) {}

  static createFromString(candleString: string) {
    const candleArray = candleString.split(",");
    const candleData: OhlcvCandleData = [
      parseInt(candleArray[0]),
      parseFloat(candleArray[1]),
      parseFloat(candleArray[2]),
      parseFloat(candleArray[3]),
      parseFloat(candleArray[4]),
      parseFloat(candleArray[5]),
      parseInt(candleArray[6]),
      parseInt(candleArray[7]),
      candleArray[8] === "true",
    ];
    return new OhlcvCandle(candleData);
  }

  static createFromBar(bar: Bar) {
    const candleData: OhlcvCandleData = [
      bar.time,
      bar.open,
      bar.high,
      bar.low,
      bar.close,
      bar.volume || 0,
      -1,
      -1,
      false,
    ];
    return new OhlcvCandle(candleData);
  }

  static createFromArrayBuffer(arrayBuffer: ArrayBuffer) {
    const dataView = new DataView(arrayBuffer);
    const candleData: OhlcvCandleData = [
      dataView.getFloat64(0),
      dataView.getFloat64(8),
      dataView.getFloat64(16),
      dataView.getFloat64(24),
      dataView.getFloat64(32),
      dataView.getFloat64(40),
      dataView.getFloat64(48),
      dataView.getFloat64(56),
      dataView.getUint8(64) === 1,
    ];
    return new OhlcvCandle(candleData);
  }

  get timestamp(): number {
    return this.data[INDEX_TIMESTAMP];
  }
  set timestamp(value: number) {
    this.data[INDEX_TIMESTAMP] = value;
  }

  get open(): number {
    return this.data[INDEX_OPEN];
  }
  set open(value: number) {
    this.data[INDEX_OPEN] = value;
  }

  get high(): number {
    return this.data[INDEX_HIGH];
  }
  set high(value: number) {
    this.data[INDEX_HIGH] = value;
  }

  get low(): number {
    return this.data[INDEX_LOW];
  }
  set low(value: number) {
    this.data[INDEX_LOW] = value;
  }

  get close(): number {
    return this.data[INDEX_CLOSE];
  }
  set close(value: number) {
    this.data[INDEX_CLOSE] = value;
  }

  get volume(): number {
    return this.data[INDEX_VOLUME];
  }
  set volume(value: number) {
    this.data[INDEX_VOLUME] = value;
  }

  get firstTradeId(): number {
    return this.data[INDEX_FIRST_TRADE_ID];
  }
  set firstTradeId(value: number) {
    this.data[INDEX_FIRST_TRADE_ID] = value;
  }

  get lastTradeId(): number {
    return this.data[INDEX_LAST_TRADE_ID];
  }
  set lastTradeId(value: number) {
    this.data[INDEX_LAST_TRADE_ID] = value;
  }

  get isOpen(): boolean {
    return this.data[INDEX_IS_OPEN];
  }
  set isOpen(value: boolean) {
    this.data[INDEX_IS_OPEN] = value;
  }

  get isClosed(): boolean {
    return !this.data[INDEX_IS_OPEN];
  }
}
