export class ChartUtils {
  static convertIntervalToSec(interval: string): number {
    if (!interval.endsWith("S") && !interval.endsWith("D") && !interval.endsWith("M")) {
      return parseInt(interval) * 60;
    }

    const length = interval.length;
    const value = parseInt(interval.substr(0, length - 1));
    const type = interval.substr(length - 1);

    if (type === "S") {
      return value;
    }

    if (type === "D") {
      return value * 86400;
    }

    if (type === "M") {
      return value * 86400 * 30;
    }

    throw "Type is not supported: " + type;
  }

  static resample(value: number, bySec: number) {
    return Math.floor(value / bySec) * bySec;
  }

  static nowResampled(bySec: number) {
    const now = Math.floor(Date.now() / 1000);
    return this.resample(now, bySec);
  }
}
