import {
  UdfCompatibleConfiguration,
  UDFCompatibleDatafeedBase,
} from "@/plugins/tv_datafeeds/udf-compatible-datafeed-base";
import { MyRequester } from "@/service/tv-charting-library/my-requester";
import { QuotesProvider } from "@/plugins/tv_datafeeds/quotes-provider";
import { ResolutionString } from "@/plugins/tv_charting_library/datafeed-api";

export class MyUDFCompatibleDatafeed extends UDFCompatibleDatafeedBase {
  public constructor(datafeedURL = "/api/udf") {
    const updateFrequency: number = 1000000 * 1000;
    const headers = {};
    const requester = new MyRequester(headers);
    const quotesProvider = new QuotesProvider(datafeedURL, requester);
    super(datafeedURL, quotesProvider, requester, updateFrequency);
  }

  destroy() {
    //Extend if you need some code when destroy
  }

  protected _requestConfiguration(): Promise<UdfCompatibleConfiguration | null> {
    return Promise.resolve({
      supports_search: true,
      supports_group_request: false,
      supports_marks: false,
      supports_time: true,
      supported_resolutions: [
        "1S" as ResolutionString,
        "5S" as ResolutionString,
        "15S" as ResolutionString,
        "30S" as ResolutionString,
        "1" as ResolutionString,
        "3" as ResolutionString,
        "5" as ResolutionString,
        "15" as ResolutionString,
        "30" as ResolutionString,
        "60" as ResolutionString,
        "120" as ResolutionString,
        "360" as ResolutionString,
        "720" as ResolutionString,
        "1D" as ResolutionString,
        "3D" as ResolutionString,
      ],
      symbols_types: [
        { name: "All types", value: "" },
        { name: "Crypto", value: "crypto" },
      ],
      exchanges: [
        { value: "", name: "All Exchanges", desc: "" },
        { value: "Binance", name: "Binance", desc: "Binance description" },
        { value: "FTX", name: "FTX", desc: "FTX description" },
      ],
    });
  }
}
