import { ChartUtils } from "@/service/tv-charting-library/chart-api/chart-utils";
import { IChartWidgetApi, TimeFrameType, VisibleTimeRange } from "@/plugins/tv_charting_library/charting_library";
import { Positions } from "@/service/tv-charting-library/chart-api/positions";
import { TimeFrameParameters } from "@/service/tv-charting-library/chart-api/events";
import { Cache } from "@/service/tv-charting-library/chart-api/cache";
import { LiveDatafeed } from "@/service/tv-charting-library/live-datafeed";

export class IntervalChangeHandler {
  protected lastInterval?: string;
  // Timeout for _unsubscribeRealtime and _purgeCache on interval change
  static unsubscribeTimeout = 1000;

  constructor(protected tvChart: IChartWidgetApi, protected positions: Positions, protected cache: Cache) {}

  initLastInterval() {
    this.lastInterval = this.tvChart.resolution();
  }

  handle(interval: string, timeFrameParameters: TimeFrameParameters, beforeZoomVisibleRange?: VisibleTimeRange) {
    //NOTE: If the interval change was made by the interval changer at the top of the chart (not by alt + zooming) and
    //      also we don't have an active position.
    //      In this case we do nothing, only set right an offset for LiveDatafeed
    if (!beforeZoomVisibleRange && !this.positions.getActivePosition()) {
      //NOTE: We have to wait until the interval change is done, and we can get the datafeed based on the new interval.
      setTimeout(() => {
        const datafeed = this.cache.getMyBaseDatafeed();
        if (datafeed instanceof LiveDatafeed) {
          this.tvChart.getTimeScale().setRightOffset(10);
        }
      });

      return;
    }

    const startEndDate = this.getStartEndDate(interval, beforeZoomVisibleRange);

    if (!startEndDate) {
      return;
    }

    let startDate = startEndDate.startDate;
    let endDate = startEndDate.endDate;

    const intervalInSec = ChartUtils.convertIntervalToSec(interval);

    endDate = ChartUtils.resample(endDate, intervalInSec);
    startDate = ChartUtils.resample(startDate, intervalInSec);

    //console.log(startDate, endDate, new Date(startDate * 1000), new Date(endDate * 1000));

    timeFrameParameters.timeframe = {
      type: "time-range" as TimeFrameType.TimeRange,
      from: startDate,
      to: endDate - intervalInSec,
    };
    //console.log("Display", new Date(timeframeObj.timeframe.from * 1000), new Date(timeframeObj.timeframe.to * 1000));

    setTimeout(() => {
      const datafeedThread = this.cache.getDatafeedThread();
      const datafeed = this.cache.getMyBaseDatafeed();

      const preloadDuration = ChartUtils.resample((endDate - startDate) * 0.15, intervalInSec); // Preload 15% more data than what is visible
      const to = (startDate - preloadDuration) * 1000;

      // resetDataByRange without _unsubscribeRealtime()
      datafeed.setEndDate(endDate);
      datafeedThread._purgeCache();

      //console.log("Load data", new Date(to), new Date(datafeedThread._leftDate));
      datafeedThread._leftDate = endDate * 1000;
      datafeedThread._ensureRequestedTo({ to: to, countBack: 0 });

      //NOTE: Do faster _unsubscribeRealtime and _purgeCache on interval change.
      //      The default value was 10 seconds that is too long.
      datafeedThread._resetCacheTimePeriod = IntervalChangeHandler.unsubscribeTimeout;
    });

    this.lastInterval = interval;
  }

  private getStartEndDate(interval: string, beforeZoomVisibleRange?: VisibleTimeRange) {
    if (!this.lastInterval) {
      return;
    }

    const visibleRange = this.tvChart.getVisibleRange();

    const intervalInSec = ChartUtils.convertIntervalToSec(interval);

    let endDate = beforeZoomVisibleRange ? beforeZoomVisibleRange.to : visibleRange.to;
    let startDate = beforeZoomVisibleRange ? beforeZoomVisibleRange.from : visibleRange.from;

    const visibleTimeDuration = endDate - startDate;
    const barNumbers = visibleTimeDuration / intervalInSec;
    const middleDate = endDate - visibleTimeDuration / 2;

    const maxBarNumbers = 10000;
    const minBarNumbers = 15;
    //console.log("barNumbers interval change", barNumbers);

    if (barNumbers > maxBarNumbers) {
      const newVisibleTimeDuration = maxBarNumbers * intervalInSec;
      startDate = middleDate - newVisibleTimeDuration / 2;
      endDate = middleDate + newVisibleTimeDuration / 2;
    }

    if (barNumbers < minBarNumbers) {
      const newVisibleTimeDuration = minBarNumbers * intervalInSec;
      startDate = middleDate - newVisibleTimeDuration / 2;
      endDate = middleDate + newVisibleTimeDuration / 2;
    }

    return { startDate: startDate, endDate: endDate };
  }

  // private test3(interval: string) {
  //   const activePosition = this.positions.getActivePosition();
  //
  //   if (!activePosition || !this.lastInterval) {
  //     return;
  //   }
  //
  //   const intervalInSec = ChartUtils.convertIntervalToSec(interval);
  //   const lastIntervalInSec = ChartUtils.convertIntervalToSec(this.lastInterval);
  //   const ratio = intervalInSec / lastIntervalInSec;
  //
  //   const visibleRange = this.tvChart.getVisibleRange();
  //   let visibleTimeDuration = (visibleRange.to - visibleRange.from) * ratio;
  //
  //   const lastBarNumbers = (visibleRange.to - visibleRange.from) / lastIntervalInSec;
  //   const maxBarNumbers = 250;
  //
  //   if (lastBarNumbers > maxBarNumbers) {
  //     visibleTimeDuration *= maxBarNumbers / lastBarNumbers;
  //   }
  //
  //   const positionEntryDate = dayjs.utc(activePosition.entryDate).unix();
  //   const positionCloseDate = dayjs.utc(activePosition.closeDate).unix();
  //   const positionDuration = positionCloseDate - positionEntryDate;
  //
  //   let endDate: number;
  //   if (positionDuration > visibleTimeDuration * 0.8) {
  //     // We keep 10% distance on the left side
  //     endDate = positionEntryDate + visibleTimeDuration * 0.9;
  //   } else {
  //     const positionMiddleDate = positionEntryDate + positionDuration / 2;
  //     endDate = positionMiddleDate + visibleTimeDuration / 2;
  //   }
  //
  //   const startDate = endDate - visibleTimeDuration;
  //
  //   return { startDate, endDate };
  // }

  // private test2(interval: string) {
  //   if (!this.lastInterval) {
  //     return;
  //   }
  //
  //   const intervalInSec = ChartUtils.convertIntervalToSec(interval);
  //   const lastIntervalInSec = ChartUtils.convertIntervalToSec(this.lastInterval);
  //   const ratio = intervalInSec / lastIntervalInSec;
  //
  //   const visibleRange = this.tvChart.getVisibleRange();
  //   const lastVisibleTimeDuration = visibleRange.to - visibleRange.from;
  //   const visibleTimeDuration = (visibleRange.to - visibleRange.from) * ratio;
  //
  //   const newMiddleDate = visibleRange.from + lastVisibleTimeDuration / 2;
  //
  //   const endDate = newMiddleDate + visibleTimeDuration / 2;
  //   const startDate = endDate - visibleTimeDuration;
  //
  //   return { startDate, endDate };
  // }
}
