import { ResolutionString, SubscribeBarsCallback } from "@/plugins/tv_charting_library/datafeed-api";
import { Socket } from "socket.io-client";
import { io } from "socket.io-client";
import { MyBaseDatafeed } from "@/service/tv-charting-library/my-base-datafeed";
import { ChartApi } from "@/service/tv-charting-library/chart-api/chart-api";
import { SymbolInfo } from "@/service/tv-charting-library/chart-api/types";
import { LiveDataSocketHandler } from "@/service/tv-charting-library/live-data-socket-handler";
import { logMessage } from "@/plugins/tv_datafeeds/helpers";

class SubscriberData {
  constructor(public room: string, public socketHandler: LiveDataSocketHandler) {}
}

interface ResolutionMap {
  [val: string]: string;
}

const intervalToTimeframeMap: ResolutionMap = {
  "1S": "1s",
  "5S": "5s",
  "15S": "15s",
  "30S": "30s",
  "1": "1m",
  "3": "3m",
  "5": "5m",
  "15": "15m",
  "30": "30m",
  "60": "1h",
  "120": "2h",
  "360": "6h",
  "720": "12h",
  "1D": "1d",
  "3D": "3d",
};

export class LiveDatafeed extends MyBaseDatafeed {
  socket: Socket;
  private readonly _subscribers: Map<string, SubscriberData> = new Map();
  private liveUpdatesEnabled = true;

  constructor(socket?: Socket) {
    super();

    if (socket != null) {
      this.socket = socket;
    } else {
      this.socket = io();
    }

    this.socket.on("connect", this.connectHandler);
  }

  setEndDate(endDate?: number) {
    super.setEndDate(endDate);

    //NOTE: We disable/enable live updates based on endDate
    if (!this.chartApi) {
      return;
    }
    const ticker = this.chartApi?.getTicker();
    const interval = this.chartApi?.getInterval();
    if (endDate) {
      this.disableLiveUpdates(ticker, interval);
    } else {
      this.enableLiveUpdates(ticker, interval);
    }
  }

  getLastCandle(ticker: string, interval: string) {
    return this.getSocketHandler(ticker, interval)?.getLastCandle();
  }

  disableLiveUpdates(ticker: string, interval: string) {
    this.liveUpdatesEnabled = false;
    this.getSocketHandler(ticker, interval)?.disable();
  }

  enableLiveUpdates(ticker: string, interval: string) {
    this.liveUpdatesEnabled = true;
    this.getSocketHandler(ticker, interval)?.enable();
  }

  private getSocketHandler(ticker: string, interval: string) {
    for (const subscriber of this._subscribers.values()) {
      const socketHandler = subscriber.socketHandler;
      if (socketHandler.symbolInfo.ticker === ticker && socketHandler.interval === interval) {
        return socketHandler;
      }
    }
    return undefined;
  }

  setChartApi(chartApi: ChartApi) {
    super.setChartApi(chartApi);
    for (const subscriber of this._subscribers.values()) {
      subscriber.socketHandler.setChartApi(chartApi);
    }
  }

  //Note: We need to use the same handler for "on" and "off" events. It needs to be in this format, so "this" means this class.
  private connectHandler = () => {
    for (const subscriberData of this._subscribers.values()) {
      this.socket.emit("join", subscriberData.room);
      console.log("Joined room on connect", subscriberData.room);
    }
  };

  destroy() {
    this.socket.off("connect", this.connectHandler);
    this.socket.disconnect();
  }

  subscribeBars(
    symbolInfo: SymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void
  ): void {
    const ticker = symbolInfo.ticker;
    this.onResetCacheNeededCallbacks.set(ticker + "@" + resolution, onResetCacheNeededCallback);

    if (this._subscribers.has(listenerGuid)) {
      console.error(`DataPulseProvider: already has subscriber with id=${listenerGuid}`);
      return;
    }

    const interval = resolution;
    const timeframe = intervalToTimeframeMap[interval];
    if (timeframe == null) {
      console.error("Interval does not exists in our map", interval, intervalToTimeframeMap);
      return;
    }

    const room = "ohlcv@" + symbolInfo.ticker + "_" + timeframe;
    if (this.socket.connected) {
      this.socket.emit("join", room);
      //console.log("Joined room", room);
    }

    const socketHandler = new LiveDataSocketHandler(symbolInfo, interval, this, this.liveUpdatesEnabled, onTick);
    if (this.chartApi) {
      socketHandler.setChartApi(this.chartApi);
    }

    this.socket.on(room, socketHandler.socketListener);

    this._subscribers.set(listenerGuid, new SubscriberData(room, socketHandler));

    logMessage(`DataPulseProvider: subscribed for #${listenerGuid} - {${symbolInfo.name}, ${interval}}`);
  }

  unsubscribeBars(listenerGuid: string): void {
    const subscriberData = this._subscribers.get(listenerGuid);
    if (!subscriberData) {
      return;
    }

    this.socket.emit("leave", subscriberData.room);
    this.socket.off(subscriberData.room, subscriberData.socketHandler.socketListener);
    //console.log("Left room", subscriberData.room);

    this._subscribers.delete(listenerGuid);

    logMessage(`DataPulseProvider: unsubscribed for #${listenerGuid}`);
  }
}
